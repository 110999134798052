<template>
  <div class="surgical m-4">
    <div class="row m-0 p-0">
      <div class="titles col-6">Preanestésica</div>
      <div class="col-6 text-end" v-if="!isEditActive">
        <!-- ACTIVE EDIT -->
        <button
          class="btn btn-primary me-1 w-20"
          @click="changeEditStatus(true)"
          v-text="'Editar'"
          type="button" />
        <!-- /ACTIVE EDIT -->
      </div>
    </div>
    <form class="mt-4" @submit.stop.prevent="actionSave">
      <div class="row mb-5">
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="nec">
              Cirugía Proyectada
            </label>
            <div class="col-sm-7 col-lg-8">
              <select
                v-model="preanesthetic.projected_surgery"
                class="form-control inputsGeneralClassThird winput-50"
                :class="{
                  error:
                    $v.preanesthetic.projected_surgery.$error ||
                    $v.preanesthetic.projected_surgery.$invalid,
                }"
                :disabled="!isEditActive">
                <option value="null" disabled selected>Tipo de Cirugía</option>
                <option :value="'effective'">Efectiva</option>
                <option :value="'urgent'">Urgente</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="name">
              Antecedentes Heredofamiliares
            </label>
            <div class="col-sm-7 col-lg-8">
              <select
                v-model="preanesthetic.family_history"
                :class="{
                  error:
                    $v.preanesthetic.family_history.$error ||
                    $v.preanesthetic.family_history.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive">
                <option value="null" disabled selected>
                  Tipo de Antecedente
                </option>
                <option
                  v-for="(
                    label, index
                  ) in importedConstants.heredorelativeLabels"
                  :value="label.value"
                  v-text="label.label"
                  :key="`diabetes-${label.label}-${index}`"></option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="nec">
              Otros
            </label>
            <div class="col-sm-7 col-lg-8">
              <input
                v-model="preanesthetic.family_history_other"
                :class="{
                  error:
                    $v.preanesthetic.family_history_other.$error ||
                    $v.preanesthetic.family_history_other.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="name">
              Antecedentes personales no patológicos
            </label>
            <div class="col-sm-7 col-lg-8">
              <select
                v-model="preanesthetic.personal_history"
                :class="{
                  error:
                    $v.preanesthetic.personal_history.$error ||
                    $v.preanesthetic.personal_history.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive">
                <option value="null" disabled selected>
                  Tipo de Antecedente
                </option>
                <option
                  v-for="(
                    label, index
                  ) in importedConstants.notPathologicalsLabels"
                  :value="label.value"
                  v-text="label.label"
                  :key="`diabetes-${label.label}-${index}`"></option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="nec">
              Otros
            </label>
            <div class="col-sm-7 col-lg-8">
              <input
                v-model="preanesthetic.personal_history_other"
                :class="{
                  error:
                    $v.preanesthetic.personal_history_other.$error ||
                    $v.preanesthetic.personal_history_other.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="name">
              Antecedentes personales patológicos
            </label>
            <div class="col-sm-7 col-lg-8">
              <select
                v-model="preanesthetic.personal_history_pathological"
                :class="{
                  error:
                    $v.preanesthetic.personal_history_pathological.$error ||
                    $v.preanesthetic.personal_history_pathological.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive">
                <option value="null" disabled selected>
                  Tipo de Antecedente
                </option>
                <option
                  v-for="(
                    label, index
                  ) in importedConstants.pathologicalsLabels"
                  :value="label.value"
                  v-text="label.label"
                  :key="`diabetes-${label.label}-${index}`"></option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="nec">
              Otros
            </label>
            <div class="col-sm-7 col-lg-8">
              <input
                v-model="preanesthetic.personal_history_pathological_other"
                :class="{
                  error:
                    $v.preanesthetic.personal_history_pathological_other
                      .$error ||
                    $v.preanesthetic.personal_history_pathological_other
                      .$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="name">
              Embarazada Actualmente
            </label>
            <div class="col-sm-7 col-lg-8">
              <select
                v-model="preanesthetic.currently_pregnant"
                :class="{
                  error:
                    $v.preanesthetic.currently_pregnant.$error ||
                    $v.preanesthetic.currently_pregnant.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive">
                <option value="null" disabled selected>
                  Seleccione una opción
                </option>
                <option :value="true">Si</option>
                <option :value="false">No</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="nec">
              Semanas de Gestación
            </label>
            <div class="col-sm-7 col-lg-8">
              <input
                v-model="preanesthetic.gestation_weeks"
                :class="{
                  error:
                    $v.preanesthetic.gestation_weeks.$error ||
                    $v.preanesthetic.gestation_weeks.$invalid,
                }"
                type="number"
                @keypress="isNumber($event)"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <label class="mb-1" for="surgical_technique"> Comentarios </label>
        <textarea
          v-model="preanesthetic.comments"
          :class="{
            error:
              $v.preanesthetic.comments.$error ||
              $v.preanesthetic.comments.$invalid,
          }"
          :disabled="!isEditActive"
          class="form-control inputsGeneralClassThird"
          name="surgical_technique"
          id="surgical_technique"
          cols="30"
          rows="5"></textarea>
      </div>
      <div class="row mb-5">
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="name">
              Cardiovascular
            </label>
            <div class="col-sm-7 col-lg-8">
              <select
                v-model="preanesthetic.cardiovascular"
                :class="{
                  error:
                    $v.preanesthetic.cardiovascular.$error ||
                    $v.preanesthetic.cardiovascular.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive">
                <option value="null" disabled selected>
                  Seleccione una opción
                </option>
                <option
                  v-for="(
                    label, index
                  ) in importedConstants.cardiovascularLabels"
                  :value="label.value"
                  v-text="label.label"
                  :key="`diabetes-${label.label}-${index}`"></option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="name">
              Respiratorio
            </label>
            <div class="col-sm-7 col-lg-8">
              <select
                v-model="preanesthetic.respiratory"
                :class="{
                  error:
                    $v.preanesthetic.respiratory.$error ||
                    $v.preanesthetic.respiratory.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive">
                <option value="null" disabled selected>
                  Seleccione una opción
                </option>
                <option
                  v-for="(label, index) in importedConstants.respiratoryLabels"
                  :value="label.value"
                  v-text="label.label"
                  :key="`diabetes-${label.label}-${index}`"></option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="name">
              Renal
            </label>
            <div class="col-sm-7 col-lg-8">
              <select
                v-model="preanesthetic.renal"
                :class="{
                  error:
                    $v.preanesthetic.renal.$error ||
                    $v.preanesthetic.renal.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive">
                <option value="null" disabled selected>
                  Seleccione una opción
                </option>
                <option
                  v-for="(label, index) in importedConstants.renalLabels"
                  :value="label.value"
                  v-text="label.label"
                  :key="`diabetes-${label.label}-${index}`"></option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="name">
              Digestivo
            </label>
            <div class="col-sm-7 col-lg-8">
              <select
                v-model="preanesthetic.digestive"
                :class="{
                  error:
                    $v.preanesthetic.digestive.$error ||
                    $v.preanesthetic.digestive.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive">
                <option value="null" disabled selected>
                  Seleccione una opción
                </option>
                <option
                  v-for="(label, index) in importedConstants.digestiveLabels"
                  :value="label.value"
                  v-text="label.label"
                  :key="`diabetes-${label.label}-${index}`"></option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="name">
              Músculo esquelético
            </label>
            <div class="col-sm-7 col-lg-8">
              <select
                v-model="preanesthetic.skeletal_muscle"
                :class="{
                  error:
                    $v.preanesthetic.skeletal_muscle.$error ||
                    $v.preanesthetic.skeletal_muscle.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive">
                <option value="null" disabled selected>
                  Seleccione una opción
                </option>
                <option
                  v-for="(
                    label, index
                  ) in importedConstants.skeletalmuscleLabels"
                  :value="label.value"
                  v-text="label.label"
                  :key="`diabetes-${label.label}-${index}`"></option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="name">
              Transtorno de Sueño
            </label>
            <div class="col-sm-7 col-lg-8">
              <select
                v-model="preanesthetic.sleep_disorder"
                :class="{
                  error:
                    $v.preanesthetic.sleep_disorder.$error ||
                    $v.preanesthetic.sleep_disorder.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive">
                <option value="null" disabled selected>
                  Seleccione una opción
                </option>
                <option
                  v-for="(
                    label, index
                  ) in importedConstants.sleepDisorderLabels"
                  :value="label.value"
                  v-text="label.label"
                  :key="`diabetes-${label.label}-${index}`"></option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="name">
              Neurológico
            </label>
            <div class="col-sm-7 col-lg-8">
              <select
                v-model="preanesthetic.neurological"
                :class="{
                  error:
                    $v.preanesthetic.neurological.$error ||
                    $v.preanesthetic.neurological.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive">
                <option value="null" disabled selected>
                  Seleccione una opción
                </option>
                <option
                  v-for="(
                    label, index
                  ) in importedConstants.neurologicalsLabels"
                  :value="label.value"
                  v-text="label.label"
                  :key="`diabetes-${label.label}-${index}`"></option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="name">
              Mallapanti
            </label>
            <div class="col-sm-7 col-lg-8">
              <input
                v-model="preanesthetic.malampati"
                :class="{
                  error:
                    $v.preanesthetic.malampati.$error ||
                    $v.preanesthetic.malampati.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive" />
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="nec">
              Patil Andreti
            </label>
            <div class="col-sm-7 col-lg-8">
              <input
                v-model="preanesthetic.patil_aldretti"
                :class="{
                  error:
                    $v.preanesthetic.patil_aldretti.$error ||
                    $v.preanesthetic.patil_aldretti.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="name">
              ASA
            </label>
            <div class="col-sm-7 col-lg-8">
              <select
                v-model="preanesthetic.asa"
                :class="{
                  error:
                    $v.preanesthetic.asa.$error ||
                    $v.preanesthetic.asa.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive">
                <option value="null" disabled selected>
                  Seleccione una opción
                </option>
                <option
                  v-for="(label, index) in importedConstants.ASALabels"
                  :value="label.value"
                  v-text="label.label"
                  :key="`diabetes-${label.label}-${index}`"></option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="name">
              NYHA
            </label>
            <div class="col-sm-7 col-lg-8">
              <input
                v-model="preanesthetic.nyha"
                :class="{
                  error:
                    $v.preanesthetic.nyha.$error ||
                    $v.preanesthetic.nyha.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive" />
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="nec">
              GOLDMAN
            </label>
            <div class="col-sm-7 col-lg-8">
              <input
                v-model="preanesthetic.goldman"
                :class="{
                  error:
                    $v.preanesthetic.goldman.$error ||
                    $v.preanesthetic.goldman.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-6 col-lg-4 col-form-label m-auto" for="name">
              Riesgo tromboembólico
            </label>
            <div class="col-sm-6 col-lg-8">
              <input
                v-model="preanesthetic.thromboembolic_risk"
                :class="{
                  error:
                    $v.preanesthetic.thromboembolic_risk.$error ||
                    $v.preanesthetic.thromboembolic_risk.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="name">
              HB
            </label>
            <div class="col-sm-7 col-lg-8">
              <input
                v-model="preanesthetic.hb"
                :class="{
                  error:
                    $v.preanesthetic.hb.$error || $v.preanesthetic.hb.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive" />
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="nec">
              Glucosa
            </label>
            <div class="col-sm-7 col-lg-8">
              <input
                v-model="preanesthetic.glucose"
                :class="{
                  error:
                    $v.preanesthetic.glucose.$error ||
                    $v.preanesthetic.glucose.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="name">
              Hto
            </label>
            <div class="col-sm-7 col-lg-8">
              <input
                v-model="preanesthetic.hto"
                :class="{
                  error:
                    $v.preanesthetic.hto.$error ||
                    $v.preanesthetic.hto.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive" />
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="nec">
              Urea
            </label>
            <div class="col-sm-7 col-lg-8">
              <input
                v-model="preanesthetic.urea"
                :class="{
                  error:
                    $v.preanesthetic.urea.$error ||
                    $v.preanesthetic.urea.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="name">
              Plaquetas
            </label>
            <div class="col-sm-7 col-lg-8">
              <input
                v-model="preanesthetic.platelets"
                :class="{
                  error:
                    $v.preanesthetic.platelets.$error ||
                    $v.preanesthetic.platelets.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive" />
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="nec">
              Creatina
            </label>
            <div class="col-sm-7 col-lg-8">
              <input
                v-model="preanesthetic.creatinine"
                :class="{
                  error:
                    $v.preanesthetic.creatinine.$error ||
                    $v.preanesthetic.creatinine.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="name">
              Leocusitos
            </label>
            <div class="col-sm-7 col-lg-8">
              <input
                v-model="preanesthetic.leukocytes"
                :class="{
                  error:
                    $v.preanesthetic.leukocytes.$error ||
                    $v.preanesthetic.leukocytes.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive" />
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="nec">
              BUN
            </label>
            <div class="col-sm-7 col-lg-8">
              <input
                v-model="preanesthetic.bun"
                :class="{
                  error:
                    $v.preanesthetic.bun.$error ||
                    $v.preanesthetic.bun.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="name">
              TP
            </label>
            <div class="col-sm-7 col-lg-8">
              <input
                v-model="preanesthetic.tp"
                :class="{
                  error:
                    $v.preanesthetic.tp.$error || $v.preanesthetic.tp.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive" />
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="nec">
              AST
            </label>
            <div class="col-sm-7 col-lg-8">
              <input
                v-model="preanesthetic.ast"
                :class="{
                  error:
                    $v.preanesthetic.ast.$error ||
                    $v.preanesthetic.ast.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="name">
              TPT
            </label>
            <div class="col-sm-7 col-lg-8">
              <input
                v-model="preanesthetic.tpt"
                :class="{
                  error:
                    $v.preanesthetic.tpt.$error ||
                    $v.preanesthetic.tpt.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive" />
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="nec">
              ALT
            </label>
            <div class="col-sm-7 col-lg-8">
              <input
                v-model="preanesthetic.alt"
                :class="{
                  error:
                    $v.preanesthetic.alt.$error ||
                    $v.preanesthetic.alt.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="name">
              INR
            </label>
            <div class="col-sm-7 col-lg-8">
              <input
                v-model="preanesthetic.inr"
                :class="{
                  error:
                    $v.preanesthetic.inr.$error ||
                    $v.preanesthetic.inr.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive" />
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="nec">
              BD
            </label>
            <div class="col-sm-7 col-lg-8">
              <input
                v-model="preanesthetic.bd"
                :class="{
                  error:
                    $v.preanesthetic.bd.$error || $v.preanesthetic.bd.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-6"></div>
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="nec">
              BI
            </label>
            <div class="col-sm-7 col-lg-8">
              <input
                v-model="preanesthetic.bi"
                :class="{
                  error:
                    $v.preanesthetic.bi.$error || $v.preanesthetic.bi.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="name">
              K
            </label>
            <div class="col-sm-7 col-lg-8">
              <input
                v-model="preanesthetic.k"
                :class="{
                  error:
                    $v.preanesthetic.k.$error || $v.preanesthetic.k.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive" />
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="nec">
              Técnica Anestesica
            </label>
            <div class="col-sm-7 col-lg-8">
              <select
                v-model="preanesthetic.anesthetic_technique"
                :class="{
                  error:
                    $v.preanesthetic.anesthetic_technique.$error ||
                    $v.preanesthetic.anesthetic_technique.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive">
                <option value="null" disabled selected>
                  Seleccione una opción
                </option>
                <option
                  v-for="(label, index) in importedConstants.anestheticLabels"
                  :value="label.value"
                  v-text="label.label"
                  :key="`diabetes-${label.label}-${index}`"></option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="name">
              CI
            </label>
            <div class="col-sm-7 col-lg-8">
              <input
                v-model="preanesthetic.ci"
                :class="{
                  error:
                    $v.preanesthetic.ci.$error || $v.preanesthetic.ci.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="name">
              Colesterol
            </label>
            <div class="col-sm-7 col-lg-8">
              <input
                v-model="preanesthetic.cholesterol"
                :class="{
                  error:
                    $v.preanesthetic.cholesterol.$error ||
                    $v.preanesthetic.cholesterol.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-4 col-form-label m-auto" for="name">
              Triglicéridos
            </label>
            <div class="col-sm-7 col-lg-8">
              <input
                v-model="preanesthetic.triglycerides"
                :class="{
                  error:
                    $v.preanesthetic.triglycerides.$error ||
                    $v.preanesthetic.triglycerides.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50"
                :disabled="!isEditActive" />
            </div>
          </div>
        </div>
      </div>

      <!-- Footer buttons -->
      <div class="row pt-5 justify-content-end">
        <div class="col-2" v-if="isEditActive">
          <!-- CANCEL -->
          <button
            class="btn btn-secondary me-1 w-100"
            @click="changeEditStatus(false)"
            v-text="'Cancelar'"
            type="button" />
          <!-- CANCEL /-->
        </div>
        <div class="col-2" v-if="isEditActive">
          <!-- GUARDAR -->
          <button
            class="btn btn-primary me-1 w-100"
            type="submit"
            v-text="'Guardar'" />
          <!-- GUARDAR /-->
        </div>
      </div>
      <!-- /Footer buttons -->
    </form>
  </div>
</template>

<script>
import importedConstants from "@/constants/surgery/preanesthetic";
import surgeryForms from "@/mixin/surgeryForms.mixin";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Preanesthetic",
  mixins: [surgeryForms],
  props: {
    idSurgeryEvent: {
      type: Number,
    },
  },
  data() {
    const { preanesthetic } = importedConstants;
    return { importedConstants, preanesthetic };
  },
  validations: {
    preanesthetic: {
      alt: {
        required,
      },
      anesthetic_technique: {
        required,
      },
      asa: {
        required,
      },
      ast: {
        required,
      },
      bd: {
        required,
      },
      bi: {
        required,
      },
      bun: {
        required,
      },
      cardiovascular: {
        required,
      },
      cholesterol: {
        required,
      },
      ci: {
        required,
      },
      comments: {
        required,
      },
      creatinine: {
        required,
      },
      currently_pregnant: {
        required,
      },
      digestive: {
        required,
      },
      editable: {
        required,
      },
      family_history: {
        required,
      },
      family_history_other: {
        required,
      },
      gestation_weeks: {
        required,
      },
      glucose: {
        required,
      },
      goldman: {
        required,
      },
      hb: {
        required,
      },
      hto: {
        required,
      },
      inr: {
        required,
      },
      k: {
        required,
      },
      leukocytes: {
        required,
      },
      malampati: {
        required,
      },
      neurological: {
        required,
      },
      nyha: {
        required,
      },
      operation_room_id: {
        required,
      },
      operation_room_preanesthetic_id: {
        required,
      },
      patil_aldretti: {
        required,
      },
      personal_history: {
        required,
      },
      personal_history_other: {
        required,
      },
      personal_history_pathological: {
        required,
      },
      personal_history_pathological_other: {
        required,
      },
      platelets: {
        required,
      },
      projected_surgery: {
        required,
      },
      renal: {
        required,
      },
      respiratory: {
        required,
      },
      skeletal_muscle: {
        required,
      },
      sleep_disorder: {
        required,
      },
      thromboembolic_risk: {
        required,
      },
      tp: {
        required,
      },
      tpt: {
        required,
      },
      triglycerides: {
        required,
      },
      urea: {
        required,
      },
    },
  },
  mounted() {
    if (this.idSurgeryEvent) {
      this.fillInfo();
    }
  },
  methods: {
    cancelFormEdit() {
      this.presurgical.reset();
      this.fillInfo();
    },
    fillInfo() {
      this.$api.patient
        .getSurgeriesPreanesthetic({ operation_room_id: this.idSurgeryEvent })
        .then((res) => {
          this.preanesthetic.update(res.data);
        });
    },
    actionSave() {
      if (!this.isFormDataValid()) return;
      const payload = this.preanesthetic.data();
      this.$api.patient.putSurgeriesPreanesthetic(payload).then(() => {
        this.changeEditStatus(false);
        this.getSurgeriesPreanesthetic();
      });
    },
  },
};
</script>
