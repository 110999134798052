<template>
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <p
        class="nav-link"
        :disabled="!isEditActive"
        @click="changeActiveTab('presurgical')"
        :class="{
          active: type === 'presurgical',
          pointer: !isEditActive,
          disabled: isEditActive,
        }">
        Prequirúrgica
      </p>
    </li>
    <li class="nav-item">
      <p
        class="nav-link"
        :disabled="!isEditActive"
        @click="changeActiveTab('surgical')"
        :class="{
          active: type === 'surgical',
          pointer: !isEditActive,
          disabled: isEditActive,
        }">
        Quirúrgica
      </p>
    </li>
    <li class="nav-item" v-if="getUserType === 'superadmin'">
      <p
        class="nav-link"
        @click="changeActiveTab('preanesthetic')"
        :class="{
          active: type === 'preanesthetic',
          pointer: !isEditActive,
          disabled: isEditActive,
        }">
        Preanestésica
      </p>
    </li>
    <li class="nav-item">
      <p
        class="nav-link"
        :disabled="!isEditActive"
        @click="changeActiveTab('follicularPuncture')"
        :class="{
          active: type === 'follicularPuncture',
          pointer: !isEditActive,
          disabled: isEditActive,
        }">
        Punción Folicular
      </p>
    </li>
    <li class="nav-item">
      <p
        class="nav-link"
        :disabled="!isEditActive"
        @click="changeActiveTab('embryoTransfer')"
        :class="{
          active: type === 'embryoTransfer',
          pointer: !isEditActive,
          disabled: isEditActive,
        }">
        Transferencia Embrionaria
      </p>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SurgeryTabs",
  props: {
    isEditActive: {
      type: Boolean,
    },
  },
  data() {
    return {
      type: "presurgical",
    };
  },
  methods: {
    changeActiveTab(activeTab) {
      this.$emit("changeActiveComponent", activeTab);
      this.type = activeTab;
    },
  },
  computed: {
    ...mapGetters(["getUserType"]),
  },
};
</script>
