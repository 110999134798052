import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
  data() {
    return {
      isEditActive: false,
    };
  },
  computed: {
    ...mapGetters(["getClinic"]),
  },
  methods: {
    ...mapActions(["updateAlerts"]),
    search: _.debounce((loading, search, type, vm) => {
      const vmSecondLayer = vm;
      vm.$api.patient
        .searchUsers({
          clinic_id: vm.getClinic,
          user_type: type,
          search,
        })
        .then((res) => {
          const optionsKey = `${type}s`;
          vmSecondLayer[optionsKey] = res.data.users;
          loading(false);
        });
    }, 350),
    isNumber(evt) {
      // eslint-disable-next-line no-param-reassign
      evt = evt || window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        (charCode !== 8 && charCode !== 0 && charCode < 48) ||
        charCode > 57
      ) {
        evt.preventDefault();
      }
    },
    fillSearchOptions(type, search) {
      if (!search) return;
      this.$api.patient
        .searchUsers({
          clinic_id: this.getClinic,
          user_type: type,
          search,
        })
        .then((res) => {
          const optionsKey = `${type}s`;
          this[optionsKey] = res.data.users;
        });
    },
    onSearch(search, loading, type) {
      if (search.length) {
        loading(true);
        this.search(loading, search, type, this);
      }
    },
    typeUserSearch(isExternal) {
      if (isExternal) return "Externo";
      return "Interno";
    },
    changeEditStatus(status) {
      this.$emit("activeEdit", status);
      this.isEditActive = status;
      if (!status) {
        this.cancelFormEdit();
      }
    },
    isFormDataValid() {
      this.$v.$reset();
      this.$v.$touch();
      const isValid = !this.$v.$error && !this.$v.$invalid;
      if (!isValid) {
        this.updateAlerts({
          status: "warning",
          content: "Debe llenar los campos.",
        });
      }
      return isValid;
    },
  },
};
