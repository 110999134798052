const confirmStatus = {
  confirmed: {
    label: "Confirmada",
    class: "text-primary",
  },
  pending: {
    label: "Pendiente",
    class: "text-secondary",
  },
  schedule: {
    label: "Agendar",
    class: "text-secondary",
  },
};

const smokingQuantityLabels = [
  { label: "Social", value: "social" },
  { label: "1 a 3 diarios", value: "from_1_to_3_daily" },
  { label: "4 a 10 diarios", value: "from_4_to_10_daily" },
  { label: "10 a 19 diarios", value: "from_10_to_19_daily" },
  { label: "mas de 20", value: "more_than_20" },
];

const diabetesLabels = [
  { label: "No", value: "no" },
  { label: "Controlada", value: "controlled" },
  { label: "Descontrolada", value: "uncontrolled" },
  { label: "De larga evolución", value: "long_evolution" },
  { label: "Con complicaciónes", value: "with_complications" },
  { label: "Resistencia a insulina", value: "insulin_resistance" },
];

const hypertensionLabels = [
  { label: "No", value: "no" },
  { label: "Controlada", value: "controlled" },
  { label: "Descontrolada", value: "uncontrolled" },
];

const fieldsPatients = [
  {
    class: "text-center text-primary",
    key: "status",
    label: "",
  },
  {
    class: "text-center text-primary",
    key: "medic",
    label: "Responsable",
  },
  {
    class: "text-center text-primary",
    key: "filiation_num",
    label: "Filiación",
  },
  {
    class: "text-center",
    key: "created_by_username",
    label: "Agendado por",
  },
  { class: "text-center text-primary", key: "nec", label: "NEC" },
  {
    class: "text-center text-primary",
    key: "patient_type",
    label: "Tipo",
  },
  { class: "text-center", key: "name", label: "Nombre" },
  { class: "text-center", key: "next_appointment", label: "Próxima cita" },
  { class: "text-center", key: "confirmation", label: "Asistencia" },
  { class: "text-center", key: "service", label: "Servicio" },
  { class: "text-center", key: "actions", label: "Acciones" },
];

const fieldsFiliations = [
  {
    class: "text-center text-primary",
    key: "status",
    label: "",
  },
  {
    class: "text-center text-primary",
    key: "medic",
    label: "Responsable",
  },
  {
    class: "text-center text-primary",
    key: "filiation_num",
    label: "Filiación",
  },
  {
    class: "text-center",
    key: "created_by_username",
    label: "Agendado por",
  },
  {
    class: "text-center text-primary",
    key: "patient_type",
    label: "Tipo",
  },
  { class: "text-center text-primary", key: "name", label: "Nombre" },
  { class: "text-center", key: "next_appointment", label: "Próxima cita" },
  { class: "text-center", key: "confirmation", label: "Asistencia" },
  { class: "text-center", key: "service", label: "Servicio" },
  { class: "text-center", key: "contact_tel", label: "Contacto" },
  { class: "text-center", key: "actions", label: "" },
];

const folders = [
  {
    name: "Historial",
    option: "historical",
    icon: "file-alt",
    iconExt: "fas",
    components: ["Historical", "CreateMedicNote"],
    isCreated: true,
    permissionByUserType: [
      "superadmin",
      "manager",
      "control",
      "medic",
      "admin",
      "abc",
      "manager_abc",
      "laboratory",
      "cash_register",
      "nursing",
    ],
    permissionByUserTypeSecondary: ["superadmin", "medic"],
  },
  {
    name: "Antecedentes",
    option: "record",
    icon: "file-medical-alt",
    iconExt: "fas",
    components: ["Record", "CreateMedicNote"],
    isCreated: true,
    permissionByUserType: [
      "superadmin",
      "manager",
      "medic",
      "admin",
      "nursing",
    ],
    permissionByUserTypeSecondary: ["superadmin", "medic"],
  },
  {
    name: "Laboratorios",
    option: "laboratories",
    icon: "microscope",
    iconExt: "fas",
    components: ["Laboratories", "CreateMedicNote"],
    isCreated: true,
    permissionByUserType: [
      "superadmin",
      "manager",
      "control",
      "medic",
      "admin",
      "laboratory",
      "cash_register",
      "nursing",
    ],
    permissionByUserTypeSecondary: ["superadmin", "medic"],
  },
  {
    name: "ABC",
    option: "ABC",
    icon: "folder-open",
    iconExt: "fas",
    components: ["ABC", "ABCNotes"],
    isCreated: true,
    permissionByUserType: [
      "superadmin",
      "manager",
      "control",
      "medic",
      "admin",
      "abc",
      "manager_abc",
      "cash_register",
    ],
    permissionByUserTypeSecondary: ["superadmin", "abc"],
  },
  {
    name: "Archivos",
    option: "files",
    icon: "file-archive",
    iconExt: "fas",
    components: ["Files", "FilesCard"],
    isCreated: true,
    permissionByUserType: [
      "superadmin",
      "admin",
      "manager",
      "abc",
      "manager_abc",
      "control",
      "medic",
      "laboratory",
      "nursing",
    ],
    permissionByUserTypeSecondary: [
      "superadmin",
      "admin",
      "abc",
      "manager_abc",
      "control",
      "medic",
      "manager",
      "laboratory",
      "nursing",
    ],
  },
  {
    name: "Psicología",
    option: "psychology",
    icon: "brain",
    iconExt: "fas",
    components: ["Psychology", "PsychologyCard"],
    isCreated: true,
    permissionByUserType: [
      "superadmin",
      "manager",
      "control",
      "medic",
      "admin",
      "psychology",
    ],
    permissionByUserTypeSecondary: ["superadmin", "psychology"],
  },
  {
    name: "Nutrición",
    option: "nutrition",
    icon: "weight",
    iconExt: "fas",
    components: ["Nutrition", "NutritionCard"],
    isCreated: true,
    permissionByUserType: [
      "superadmin",
      "manager",
      "control",
      "medic",
      "admin",
      "nutrition",
    ],
    permissionByUserTypeSecondary: ["superadmin", "nutrition"],
  },
  {
    name: "Exploración",
    option: "exploration",
    icon: "clipboard-check",
    iconExt: "fas",
    components: ["Exploration", "x"],
    isCreated: false,
    permissionByUserType: [
      "superadmin",
      "manager",
      "control",
      "medic",
      "admin",
    ],
    permissionByUserTypeSecondary: ["superadmin", "nutrition"],
  },
  {
    name: "Ciclos",
    option: "cycles",
    icon: "file-signature",
    iconExt: "fas",
    components: ["Cycles", "x"],
    isCreated: false,
    permissionByUserType: [
      "superadmin",
      "manager",
      "control",
      "medic",
      "admin",
    ],
    permissionByUserTypeSecondary: [
      "superadmin",
      "manager",
      "control",
      "medic",
      "admin",
    ],
  },
];

const bannerValues = [
  {
    title: "Plan",
    key: "plan_name",
    value: "",
  },
  {
    title: "Precio",
    key: "cost",
    value: "",
  },
  {
    title: "Pagado",
    key: "paid",
    value: "",
  },
  {
    title: "Adeudo",
    key: "owe",
    value: "",
  },
  {
    title: "Intentos",
    key: "attempts",
    value: "",
  },
  {
    title: "Realizados",
    key: "realized",
    value: "",
  },
  {
    title: "M. Ciclos",
    key: "cycles",
    value: "",
  },
];

const serviceTypes = [
  {
    value: "change_plan",
    text: "Cambio de Plan",
    followUp: true,
  },
  {
    value: "no_proposal_study",
    text: "Sin propuesta por estudios previos para valorar tratamiento",
    followUp: true,
  },
  {
    value: "no_proposal_procedural",
    text: "Sin propuesta por procedimiento médico previo",
    followUp: true,
  },
  {
    value: "no_proposal_appointment",
    text: "Sin propuesta hasta cita presencial",
    followUp: true,
  },
  {
    value: "no_proposal_time",
    text: "Sin propuesta porque Px solicita tiempo para digerir Dx",
    followUp: true,
  },
  {
    value: "proposal_delivered",
    text: "Propuesta entregada",
    followUp: true,
  },
  {
    value: "payment_promise",
    text: "Promesa de pago",
    followUp: true,
  },
  {
    value: "closed_sale",
    text: "Venta cerrada",
    followUp: true,
  },
  {
    value: "signed_contract",
    text: "Contrato firmado",
    followUp: false,
  },
  {
    value: "discarded",
    text: "Descartada por tema médico",
    followUp: false,
  },
  {
    value: "return",
    text: "Devolución",
    followUp: false,
  },
  {
    value: "not_started",
    text: "No iniciada",
    followUp: false,
  },
];

const permissionSelectClinics = [
  "superadmin",
  "admin",
  "manager_cacit",
  "cacit",
];

const permissionSelectMedics = [
  "superadmin",
  "admin",
  "manager_cacit",
  "cacit",
];

const fieldsPatientHistoryResume = [
  { class: "text-center text-primary", key: "nec", label: "NEC" },
  { class: "text-center", key: "name", label: "Nombre" },
  { class: "text-center", key: "medic_name", label: "Responsable" },
  { class: "text-center", key: "services", label: "Servicio" },
  { class: "text-center", key: "date", label: "Fecha" },
  { class: "text-center", key: "time", label: "Hora" },
];

const serologiesLabels = [
  { label: "Si", value: 1 },
  { label: "No", value: 2 },
  { label: "No aplica", value: 3 },
];

export {
  confirmStatus,
  fieldsPatients,
  fieldsFiliations,
  folders,
  fieldsPatientHistoryResume,
  serviceTypes,
  bannerValues,
  serologiesLabels,
  smokingQuantityLabels,
  diabetesLabels,
  hypertensionLabels,
  permissionSelectClinics,
  permissionSelectMedics,
};
