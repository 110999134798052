<template>
  <div class="embryoTransfer m-4">
    <div class="row m-0 p-0">
      <div class="titles col-6">Transferencia Embrionaria</div>
      <div class="col-6 text-end" v-if="!isEditActive">
        <!-- ACTIVE EDIT -->
        <button
          class="btn btn-primary me-1 w-20"
          @click="changeEditStatus(true)"
          v-text="'Editar'"
          type="button" />
        <!-- ACTIVE EDIT /-->
      </div>
    </div>
    <form class="mt-4" @submit.stop.prevent="onSubmit">
      <div class="row mb-3">
        <!-- responsible_medic_id -->
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label
              class="col-sm-5 col-lg-2 col-form-label m-auto"
              for="responsible_medic_id">
              Médico Responsable
            </label>
            <div class="col-sm-7 col-lg-10">
              <v-select
                label="name"
                v-model="embryoTransfer.responsible_medic_id"
                :disabled="!isEditActive"
                class="form-control inputsGeneralClassThird winput-50 p-0"
                :class="{
                  error:
                    $v.embryoTransfer.responsible_medic_id.$error ||
                    $v.embryoTransfer.responsible_medic_id.$invalid,
                }"
                :filterable="false"
                :options="responsible_medics"
                :reduce="(option) => option.id"
                @search="
                  (search, loading) =>
                    onSearch(search, loading, 'responsible_medic')
                ">
                <template slot="no-options"> escribe para buscar... </template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.name }} - {{ typeUserSearch(option.external) }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.name }} - {{ typeUserSearch(option.external) }}
                  </div>
                </template>
              </v-select>
            </div>
          </div>
        </div>
        <!-- responsible_medic_id /-->
        <!-- transfer_medic_id -->
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label
              class="col-sm-5 col-lg-2 col-form-label m-auto"
              for="transfer_medic_id">
              Médico Transferencia
            </label>
            <div class="col-sm-7 col-lg-10">
              <v-select
                label="name"
                v-model="embryoTransfer.transfer_medic_id"
                :disabled="!isEditActive"
                class="form-control inputsGeneralClassThird winput-50 p-0"
                :class="{
                  error:
                    $v.embryoTransfer.transfer_medic_id.$error ||
                    $v.embryoTransfer.transfer_medic_id.$invalid,
                }"
                :filterable="false"
                :options="transfer_medics"
                :reduce="(option) => option.id"
                @search="
                  (search, loading) =>
                    onSearch(search, loading, 'transfer_medic')
                ">
                <template slot="no-options"> escribe para buscar... </template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.name }} - {{ typeUserSearch(option.external) }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.name }} - {{ typeUserSearch(option.external) }}
                  </div>
                </template>
              </v-select>
            </div>
          </div>
        </div>
        <!-- transfer_medic_id /-->
      </div>
      <div class="row mb-3">
        <!-- cannula -->
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label
              class="col-sm-5 col-lg-2 col-form-label m-auto"
              for="cannula">
              Canula
            </label>
            <div class="col-sm-7 col-lg-10">
              <select
                v-model="embryoTransfer.cannula"
                :disabled="!isEditActive"
                :class="{
                  error:
                    $v.embryoTransfer.cannula.$error ||
                    $v.embryoTransfer.cannula.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50">
                <option value="null" disabled selected>-</option>
                <option value="1">Wallace</option>
                <option value="2">Rigida</option>
                <option value="3">Sidney</option>
              </select>
            </div>
          </div>
        </div>
        <!-- cannula /-->
        <!-- transfer_type -->
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label
              class="col-sm-5 col-lg-2 col-form-label m-auto"
              for="transfer_type">
              Tipo de Transferencia
            </label>
            <div class="col-sm-7 col-lg-10">
              <select
                v-model="embryoTransfer.transfer_type"
                :disabled="!isEditActive"
                :class="{
                  error:
                    $v.embryoTransfer.transfer_type.$error ||
                    $v.embryoTransfer.transfer_type.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50">
                <option value="null" disabled selected>-</option>
                <option value="1">1</option>
                <option value="4">4</option>
              </select>
            </div>
          </div>
        </div>
        <!-- transfer_type /-->
      </div>
      <div class="row mb-3">
        <!-- transferred -->
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label
              class="col-sm-5 col-lg-2 col-form-label m-auto"
              for="transferred">
              Emb. Transferidos
            </label>
            <div class="col-sm-7 col-lg-10">
              <input
                v-model="embryoTransfer.transferred"
                class="form-control inputsGeneralClassThird winput-50"
                :class="{
                  error:
                    $v.embryoTransfer.transferred.$error ||
                    $v.embryoTransfer.transferred.$invalid,
                }"
                :disabled="!isEditActive"
                type="text" />
            </div>
          </div>
        </div>
        <!-- transferred /-->
        <!-- development_day -->
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label
              class="col-sm-5 col-lg-2 col-form-label m-auto"
              for="development_day">
              Día Desarrollo
            </label>
            <div class="col-sm-7 col-lg-10">
              <input
                v-model="embryoTransfer.development_day"
                class="form-control inputsGeneralClassThird winput-50"
                :class="{
                  error:
                    $v.embryoTransfer.development_day.$error ||
                    $v.embryoTransfer.development_day.$invalid,
                }"
                :disabled="!isEditActive"
                type="text" />
            </div>
          </div>
        </div>
        <!-- development_day /-->
      </div>
      <div class="row mb-3">
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label
              class="col-sm-5 col-lg-2 col-form-label m-auto"
              for="transferred">
              Ovocitos
            </label>
            <div class="col-sm-7 col-lg-10">
              <select
                v-model="embryoTransfer.oocytes"
                :disabled="!isEditActive"
                :class="{
                  error:
                    $v.embryoTransfer.oocytes.$error ||
                    $v.embryoTransfer.oocytes.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50">
                <option value="null" selected disabled>-</option>
                <option value="1">Propios</option>
                <option value="3">Donado</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label
              class="col-sm-5 col-lg-2 col-form-label m-auto"
              for="development_day">
              Semen
            </label>
            <div class="col-sm-7 col-lg-10">
              <select
                v-model="embryoTransfer.semen"
                :disabled="!isEditActive"
                :class="{
                  error:
                    $v.embryoTransfer.semen.$error ||
                    $v.embryoTransfer.semen.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50">
                <option value="null" selected disabled>-</option>
                <option value="4" selected>No aplica</option>
                <option value="1">Pareja</option>
                <option value="2">Banco</option>
                <option value="3">Split</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <!-- visible_drop -->
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label
              class="col-sm-5 col-lg-2 col-form-label m-auto"
              for="visible_drop">
              Gota Visible
            </label>
            <div class="col-sm-7 col-lg-10">
              <select
                v-model="embryoTransfer.visible_drop"
                :disabled="!isEditActive"
                :class="{
                  error:
                    $v.embryoTransfer.visible_drop.$error ||
                    $v.embryoTransfer.visible_drop.$invalid,
                }"
                class="form-control inputsGeneralClassThird w-50">
                <option value="null" disabled selected>-</option>
                <option value="true">Si</option>
                <option value="false">No</option>
              </select>
            </div>
          </div>
        </div>
        <!-- visible_drop /-->
        <!-- bladder -->
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label
              class="col-sm-5 col-lg-2 col-form-label m-auto"
              for="bladder">
              Vejiga
            </label>
            <div class="col-sm-7 col-lg-10">
              <select
                class="form-control inputsGeneralClassThird winput-50"
                name="bladder"
                :class="{
                  error:
                    $v.embryoTransfer.bladder.$error ||
                    $v.embryoTransfer.bladder.$invalid,
                }"
                :disabled="!isEditActive"
                v-model="embryoTransfer.bladder"
                id="bladder">
                <option
                  v-for="index in 10"
                  :value="index"
                  :key="`${index}-${Math.random()}`">
                  {{ index }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <!-- bladder /-->
      </div>
      <div class="row mb-3">
        <!-- usg_id -->
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-2 col-form-label m-auto" for="usg_id">
              USG
            </label>
            <div class="col-sm-7 col-lg-10">
              <v-select
                label="name"
                v-model="embryoTransfer.usg_id"
                :disabled="!isEditActive"
                class="form-control inputsGeneralClassThird winput-50 p-0"
                :class="{
                  error:
                    $v.embryoTransfer.usg_id.$error ||
                    $v.embryoTransfer.usg_id.$invalid,
                }"
                :filterable="false"
                :options="usgs"
                :reduce="(option) => option.id"
                @search="(search, loading) => onSearch(search, loading, 'usg')">
                <template slot="no-options"> escribe para buscar... </template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.name }} - {{ typeUserSearch(option.external) }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.name }} - {{ typeUserSearch(option.external) }}
                  </div>
                </template>
              </v-select>
            </div>
          </div>
        </div>
        <!-- usg_id /-->
        <!-- mucus_plus && mucus -->
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label
              class="col-sm-5 col-lg-2 col-form-label m-auto"
              for="mucus_plus">
              Moco
            </label>
            <div class="col-sm-7 col-lg-10">
              <select
                v-model="embryoTransfer.mucus_plus"
                :disabled="!isEditActive"
                :class="{
                  error:
                    $v.embryoTransfer.mucus_plus.$error ||
                    $v.embryoTransfer.mucus_plus.$invalid,
                }"
                class="
                  form-control
                  inputsGeneralClassThird
                  w-auto
                  d-inline
                  me-1
                ">
                <option :value="4" selected>-</option>
                <option :value="1">+</option>
                <option :value="2">++</option>
                <option :value="3">+++</option>
              </select>
              <select
                v-model="embryoTransfer.mucus"
                :disabled="!isEditActive"
                :class="{
                  error:
                    $v.embryoTransfer.mucus.$error ||
                    $v.embryoTransfer.mucus.$invalid,
                }"
                class="form-control inputsGeneralClassThird w-auto d-inline">
                <option :value="4" selected>-</option>
                <option :value="1">Dentro</option>
                <option :value="2">Fuera</option>
                <option :value="3">Ambos</option>
              </select>
            </div>
          </div>
        </div>
        <!-- mucus_plus && mucus /-->
      </div>
      <div class="row mb-3">
        <!-- blood_plus -->
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label
              class="col-sm-5 col-lg-2 col-form-label m-auto"
              for="blood_plus">
              Sangre
            </label>
            <div class="col-sm-7 col-lg-10">
              <div class="winput-50">
                <select
                  v-model="embryoTransfer.blood_plus"
                  :disabled="!isEditActive"
                  :class="{
                    error:
                      $v.embryoTransfer.blood_plus.$error ||
                      $v.embryoTransfer.blood_plus.$invalid,
                  }"
                  class="
                    form-control
                    inputsGeneralClassThird
                    w-auto
                    d-inline
                    me-2
                  ">
                  <option :value="4" selected>-</option>
                  <option :value="1">+</option>
                  <option :value="2">++</option>
                  <option :value="3">+++</option>
                </select>
                <select
                  v-model="embryoTransfer.blood"
                  :disabled="!isEditActive"
                  :class="{
                    error:
                      $v.embryoTransfer.blood.$error ||
                      $v.embryoTransfer.blood.$invalid,
                  }"
                  class="form-control inputsGeneralClassThird w-auto d-inline">
                  <option :value="4" selected>-</option>
                  <option :value="1">Dentro</option>
                  <option :value="2">Fuera</option>
                  <option :value="3">Ambos</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <!-- blood_plus /-->
        <!-- biologist_id -->
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label
              class="col-sm-5 col-lg-2 col-form-label m-auto"
              for="biologist_id">
              Biólogo Transfer
            </label>
            <div class="col-sm-7 col-lg-10">
              <v-select
                label="name"
                v-model="embryoTransfer.biologist_id"
                :disabled="!isEditActive"
                class="form-control inputsGeneralClassThird winput-50 p-0"
                :class="{
                  error:
                    $v.embryoTransfer.biologist_id.$error ||
                    $v.embryoTransfer.biologist_id.$invalid,
                }"
                :filterable="false"
                :options="biologists"
                :reduce="(option) => option.id"
                @search="
                  (search, loading) => onSearch(search, loading, 'biologist')
                ">
                <template slot="no-options"> escribe para buscar... </template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.name }} - {{ typeUserSearch(option.external) }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.name }} - {{ typeUserSearch(option.external) }}
                  </div>
                </template>
              </v-select>
            </div>
          </div>
        </div>
        <!-- biologist_id /-->
      </div>
      <div class="row mb-3">
        <!-- time_start -->
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label
              class="col-sm-5 col-lg-2 col-form-label m-auto"
              for="time_start">
              Hora Inicio
            </label>
            <div class="col-sm-7 col-lg-10">
              <date-picker
                v-model="embryoTransfer.time_start"
                :disabled="!isEditActive"
                format="HH:mm"
                value-type="format"
                :input-class="classesContructorDatePickStart"
                type="time" />
            </div>
          </div>
        </div>
        <!-- time_start /-->
        <!-- time_end -->
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label
              class="col-sm-5 col-lg-2 col-form-label m-auto"
              for="time_end">
              Hora Fin
            </label>
            <div class="col-sm-7 col-lg-10">
              <date-picker
                v-model="embryoTransfer.time_end"
                :disabled="!isEditActive"
                format="HH:mm"
                value-type="format"
                :input-class="classesContructorDatePickEnd"
                type="time" />
            </div>
          </div>
        </div>
        <!-- time_end /-->
      </div>
      <!-- surgical_technique -->
      <div class="row mb-3">
        <label class="mb-1" for="surgical_technique">
          Técnica quirúrgica y comentarios
        </label>
        <textarea
          v-model="embryoTransfer.surgical_technique"
          :disabled="!isEditActive"
          class="form-control inputsGeneralClassThird"
          :class="{
            error:
              $v.embryoTransfer.surgical_technique.$error ||
              $v.embryoTransfer.surgical_technique.$invalid,
          }"
          name="surgical_technique"
          id="surgical_technique"
          cols="30"
          rows="5"></textarea>
      </div>
      <!-- surgical_technique /-->
      <!-- ACTION BUTTONS -->
      <div class="row pt-5 justify-content-end">
        <div class="col-2" v-if="isEditActive">
          <!-- CANCEL -->
          <button
            class="btn btn-secondary me-1 w-100"
            @click="changeEditStatus(false)"
            v-text="'Cancelar'"
            type="button" />
          <!-- CANCEL /-->
        </div>
        <div class="col-2" v-if="isEditActive">
          <!-- GUARDAR -->
          <button
            class="btn btn-primary me-1 w-100"
            type="submit"
            v-text="'Guardar'" />
          <!-- GUARDAR /-->
        </div>
      </div>
      <!-- ACTION BUTTONS /-->
    </form>
  </div>
</template>

<script>
import Form from "@/helpers/form";
import { required } from "vuelidate/lib/validators";
import surgeryForms from "@/mixin/surgeryForms.mixin";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "EmbryoTransfer",
  props: {
    idSurgeryEvent: {
      type: Number,
    },
  },
  components: {
    DatePicker,
  },
  mixins: [surgeryForms],
  mounted() {
    if (this.idSurgeryEvent) {
      this.fillInfo();
    }
  },
  data() {
    return {
      responsible_medics: [],
      transfer_medics: [],
      usgs: [],
      biologists: [],
      embryoTransfer: new Form({
        responsible_medic_id: null,
        transfer_medic_id: null,
        cannula: null,
        transfer_type: null,
        oocytes: null,
        semen: null,
        transferred: null,
        development_day: null,
        visible_drop: null,
        bladder: null,
        usg_id: null,
        mucus_plus: null,
        mucus: null,
        blood_plus: null,
        blood: null,
        biologist_id: null,
        time_start: null,
        time_end: null,
        surgical_technique: null,
      }),
    };
  },
  validations: {
    embryoTransfer: {
      responsible_medic_id: { required },
      transfer_medic_id: { required },
      cannula: { required },
      transfer_type: { required },
      oocytes: { required },
      semen: { required },
      transferred: { required },
      development_day: { required },
      visible_drop: { required },
      bladder: { required },
      usg_id: { required },
      mucus_plus: { required },
      mucus: { required },
      blood_plus: { required },
      blood: { required },
      biologist_id: { required },
      time_start: { required },
      time_end: { required },
      surgical_technique: { required },
    },
  },
  computed: {
    classesContructorDatePickStart() {
      if (
        this.$v.embryoTransfer.time_start.$error ||
        this.$v.embryoTransfer.time_start.$invalid
      ) {
        return "form-control inputsGeneralClassThird error";
      }
      return "form-control inputsGeneralClassThird";
    },
    classesContructorDatePickEnd() {
      if (
        this.$v.embryoTransfer.time_end.$error ||
        this.$v.embryoTransfer.time_end.$invalid
      ) {
        return "form-control inputsGeneralClassThird error";
      }
      return "form-control inputsGeneralClassThird";
    },
  },
  methods: {
    fillInfo() {
      this.$api.patient
        .getEmbryoTransfer({
          operation_room_id: this.idSurgeryEvent,
        })
        .then((res) => {
          this.embryoTransfer.update(res.data);
          this.fillSearchOptions(
            "responsible_medic",
            res.data.responsible_medic_name
          );
          this.fillSearchOptions(
            "transfer_medic",
            res.data.transfer_medic_name
          );
          this.fillSearchOptions("usg", res.data.usg_name);
          this.fillSearchOptions("biologist", res.data.biologist_name);
        });
    },
    cancelFormEdit() {
      this.embryoTransfer.reset();
      this.fillInfo();
    },
    onSubmit() {
      if (!this.isFormDataValid()) return;
      const payload = this.embryoTransfer.data();
      payload.operation_room_id = this.idSurgeryEvent;
      this.$api.patient.saveEmbryoTransfer(payload).then(() => {
        this.changeEditStatus(false);
      });
    },
  },
};
</script>
