<template>
  <div class="follicularPuncture m-4">
    <div class="row m-0 p-0">
      <div class="titles col-6">Punción folicular</div>
      <div class="col-6 text-end" v-if="!isEditActive">
        <!-- ACTIVE EDIT -->
        <button
          class="btn btn-primary me-1 w-20"
          @click="changeEditStatus(true)"
          v-text="'Editar'"
          type="button" />
        <!-- ACTIVE EDIT /-->
      </div>
    </div>
    <form class="mt-4" @submit.stop.prevent="onSubmit">
      <div class="row mb-3">
        <!-- stimulated_by_id -->
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label
              class="col-sm-2 col-form-label m-auto"
              for="stimulated_by_id">
              Estimulada por
            </label>
            <div class="col-sm-10">
              <v-select
                label="name"
                v-model="follicularPuncture.stimulated_by_id"
                :disabled="!isEditActive"
                class="form-control inputsGeneralClassThird winput-50 p-0"
                :class="{
                  error:
                    $v.follicularPuncture.stimulated_by_id.$error ||
                    $v.follicularPuncture.stimulated_by_id.$invalid,
                }"
                :filterable="false"
                :options="stimulated_bys"
                :reduce="(option) => option.id"
                @search="
                  (search, loading) =>
                    onSearch(search, loading, 'stimulated_by')
                ">
                <template slot="no-options"> escribe para buscar... </template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.name }} - {{ typeUserSearch(option.external) }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.name }} - {{ typeUserSearch(option.external) }}
                  </div>
                </template>
              </v-select>
            </div>
          </div>
        </div>
        <!-- stimulated_by_id /-->
        <!-- puncture_medic_id -->
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label
              class="col-sm-2 col-form-label m-auto"
              for="puncture_medic_id">
              Médico Punción
            </label>
            <div class="col-sm-10">
              <v-select
                label="name"
                v-model="follicularPuncture.puncture_medic_id"
                :disabled="!isEditActive"
                class="form-control inputsGeneralClassThird winput-50 p-0"
                :class="{
                  error:
                    $v.follicularPuncture.puncture_medic_id.$error ||
                    $v.follicularPuncture.puncture_medic_id.$invalid,
                }"
                :filterable="false"
                :options="puncture_medics"
                :reduce="(option) => option.id"
                @search="
                  (search, loading) =>
                    onSearch(search, loading, 'puncture_medic')
                ">
                <template slot="no-options"> escribe para buscar... </template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.name }} - {{ typeUserSearch(option.external) }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.name }} - {{ typeUserSearch(option.external) }}
                  </div>
                </template>
              </v-select>
            </div>
          </div>
        </div>
        <!-- puncture_medic_id /-->
      </div>
      <div class="row mb-3">
        <!-- puncture_pathway - stimulation_method -->
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label
              class="col-sm-2 col-form-label m-auto"
              for="stimulation_method">
              Método de estimulación
            </label>
            <div class="col-sm-10">
              <input
                v-model="follicularPuncture.stimulation_method"
                :disabled="!isEditActive"
                :class="{
                  error:
                    $v.follicularPuncture.stimulation_method.$error ||
                    $v.follicularPuncture.stimulation_method.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50" />
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label
              class="col-sm-2 col-form-label m-auto"
              for="puncture_pathway">
              Vía de Punción
            </label>
            <div class="col-sm-10">
              <select
                v-model="follicularPuncture.puncture_pathway"
                :disabled="!isEditActive"
                :class="{
                  error:
                    $v.follicularPuncture.puncture_pathway.$error ||
                    $v.follicularPuncture.puncture_pathway.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50">
                <option value="1" selected>Vaginal</option>
              </select>
            </div>
          </div>
        </div>
        <!-- puncture_pathway - stimulation_method /-->
      </div>
      <div class="row mb-3">
        <!-- ovules_expected -->
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-2 col-form-label m-auto" for="ovules_expected">
              Óvulos Esperados
            </label>
            <div class="col-sm-10">
              <input
                v-model="follicularPuncture.ovules_expected"
                class="form-control inputsGeneralClassThird winput-50"
                :class="{
                  error:
                    $v.follicularPuncture.ovules_expected.$error ||
                    $v.follicularPuncture.ovules_expected.$invalid,
                }"
                :disabled="!isEditActive"
                type="text" />
            </div>
          </div>
        </div>
        <!-- ovules_expected /-->
        <!-- ovules_obtained -->
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-2 col-form-label m-auto" for="ovules_obtained">
              Óvulos Obtenidos
            </label>
            <div class="col-sm-10">
              <input
                v-model="follicularPuncture.ovules_obtained"
                class="form-control inputsGeneralClassThird winput-50"
                :class="{
                  error:
                    $v.follicularPuncture.ovules_obtained.$error ||
                    $v.follicularPuncture.ovules_obtained.$invalid,
                }"
                :disabled="!isEditActive"
                type="text" />
            </div>
          </div>
        </div>
        <!-- ovules_obtained /-->
      </div>
      <div class="row mb-3">
        <!-- biologist_id -->
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-2 col-form-label m-auto" for="biologist_id">
              Biólogo cuenta
            </label>
            <div class="col-sm-10">
              <v-select
                label="name"
                v-model="follicularPuncture.biologist_id"
                :disabled="!isEditActive"
                class="form-control inputsGeneralClassThird winput-50 p-0"
                :class="{
                  error:
                    $v.follicularPuncture.biologist_id.$error ||
                    $v.follicularPuncture.biologist_id.$invalid,
                }"
                :filterable="false"
                :options="biologists"
                :reduce="(option) => option.id"
                @search="
                  (search, loading) => onSearch(search, loading, 'biologist')
                ">
                <template slot="no-options"> escribe para buscar... </template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.name }} - {{ typeUserSearch(option.external) }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.name }} - {{ typeUserSearch(option.external) }}
                  </div>
                </template>
              </v-select>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-2 col-form-label m-auto" for="nurse_id">
              Enfermero participante
            </label>
            <div class="col-sm-10">
              <v-select
                label="name"
                v-model="follicularPuncture.nurse_id"
                :disabled="!isEditActive"
                class="form-control inputsGeneralClassThird winput-50 p-0"
                :class="{
                  error:
                    $v.follicularPuncture.nurse_id.$error ||
                    $v.follicularPuncture.nurse_id.$invalid,
                }"
                :filterable="false"
                :options="nurses"
                :reduce="(option) => option.id"
                @search="
                  (search, loading) => onSearch(search, loading, 'nurse')
                ">
                <template slot="no-options"> escribe para buscar... </template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.name }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.name }}
                  </div>
                </template>
              </v-select>
            </div>
          </div>
        </div>
        <!-- biologist_id /-->
      </div>
      <div class="row mb-3">
        <!-- time_entry -->
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-2 col-form-label m-auto" for="time_entry">
              Hora Entrada
            </label>
            <div class="col-sm-10">
              <date-picker
                v-model="follicularPuncture.time_entry"
                :disabled="!isEditActive"
                format="HH:mm"
                value-type="format"
                :input-class="classesContructorDatePickStart"
                type="time" />
            </div>
          </div>
        </div>
        <!-- time_entry /-->
        <!-- time_departure -->
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-2 col-form-label m-auto" for="time_departure">
              Hora Fin
            </label>
            <div class="col-sm-10">
              <date-picker
                v-model="follicularPuncture.time_departure"
                :disabled="!isEditActive"
                format="HH:mm"
                value-type="format"
                :input-class="classesContructorDatePickEnd"
                type="time" />
            </div>
          </div>
        </div>
        <!-- time_departure /-->
      </div>

      <div class="row mb-3">
        <!-- anesthesia_required -->
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label
              class="col-sm-5 col-lg-2 col-form-label m-auto"
              for="anesthesia_required">
              Requiere Anestesia
            </label>
            <div class="col-sm-7 col-lg-10">
              <select
                v-model="follicularPuncture.anesthesia_required"
                :disabled="!isEditActive"
                class="form-control inputsGeneralClassThird winput-50">
                <option :value="true">Si</option>
                <option :value="false">No</option>
              </select>
            </div>
          </div>
        </div>
        <!-- anesthesia_required /-->
      </div>

      <template v-if="follicularPuncture.anesthesia_required">
        <div class="row mb-3">
          <!-- anesthesia -->
          <div class="col-6">
            <div class="form-group mb-2 row">
              <label class="col-sm-2 col-form-label m-auto" for="anesthesia">
                Anestesia
              </label>
              <div class="col-sm-10">
                <select
                  v-model="follicularPuncture.anesthesia"
                  :disabled="!isEditActive"
                  :class="{
                    error:
                      $v.follicularPuncture.anesthesia.$error ||
                      $v.follicularPuncture.anesthesia.$invalid,
                  }"
                  class="form-control inputsGeneralClassThird winput-50">
                  <option value="null" disabled selected>
                    Tipo de Anestesia
                  </option>
                  <option :value="1">Sedación</option>
                  <option :value="2">Otra</option>
                </select>
              </div>
            </div>
          </div>
          <!-- anesthesia /-->
          <!-- anesthesia_other -->
          <div
            class="col-6"
            v-if="
              follicularPuncture.anesthesia === '2' ||
              follicularPuncture.anesthesia === 2
            ">
            <div class="form-group mb-2 row">
              <label
                class="col-sm-2 col-form-label m-auto"
                for="anesthesia_other">
                Cuál
              </label>
              <div class="col-sm-10">
                <input
                  v-model="follicularPuncture.anesthesia_other"
                  class="form-control inputsGeneralClassThird winput-50"
                  :class="{
                    error:
                      $v.follicularPuncture.anesthesia_other.$error ||
                      $v.follicularPuncture.anesthesia_other.$invalid,
                  }"
                  :disabled="!isEditActive"
                  type="text" />
              </div>
            </div>
          </div>
          <!-- anesthesia_other /-->
        </div>

        <!-- anesthesiologist_id -->
        <div class="row mb-3">
          <div class="col-6">
            <div class="form-group mb-2 row">
              <label
                class="col-sm-2 col-form-label m-auto"
                for="anesthesiologist_id">
                Anestesiólogo
              </label>
              <div class="col-sm-10">
                <v-select
                  label="name"
                  v-model="follicularPuncture.anesthesiologist_id"
                  :disabled="!isEditActive"
                  class="form-control inputsGeneralClassThird winput-50 p-0"
                  :class="{
                    error:
                      $v.follicularPuncture.anesthesiologist_id.$error ||
                      $v.follicularPuncture.anesthesiologist_id.$invalid,
                  }"
                  :filterable="false"
                  :options="anesthesiologists"
                  :reduce="(option) => option.id"
                  @search="
                    (search, loading) =>
                      onSearch(search, loading, 'anesthesiologist')
                  ">
                  <template slot="no-options">
                    escribe para buscar...
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.name }} - {{ typeUserSearch(option.external) }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.name }} - {{ typeUserSearch(option.external) }}
                    </div>
                  </template>
                </v-select>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- anesthesiologist_id /-->
      <div class="row mb-3">
        <!-- complications -->
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-2 col-form-label m-auto" for="complications">
              Complicaciones
            </label>
            <div class="col-sm-10">
              <select
                v-model="follicularPuncture.complications"
                :disabled="!isEditActive"
                :class="{
                  error:
                    $v.follicularPuncture.complications.$error ||
                    $v.follicularPuncture.complications.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50">
                <option :value="true">Si</option>
                <option :value="false">No</option>
              </select>
            </div>
          </div>
        </div>
        <!-- complications /-->
      </div>
      <!-- complications_decription -->
      <div class="row mb-3" v-if="follicularPuncture.complications">
        <label class="mb-1" for="complications_description">
          Descripción de Complicaciones
        </label>
        <textarea
          v-model="follicularPuncture.complications_description"
          :disabled="!isEditActive"
          class="form-control inputsGeneralClassThird"
          :class="{
            error:
              $v.follicularPuncture.complications_description.$error ||
              $v.follicularPuncture.complications_description.$invalid,
          }"
          name="complications_description"
          id="complications_description"
          cols="30"
          rows="5"></textarea>
      </div>
      <!-- complications_description /-->

      <!-- surgical_technique -->
      <div class="row mb-3">
        <label class="mb-1" for="surgical_technique">
          Técnica quirúrgica y comentarios
        </label>
        <textarea
          v-model="follicularPuncture.surgical_technique"
          :disabled="!isEditActive"
          class="form-control inputsGeneralClassThird"
          :class="{
            error:
              $v.follicularPuncture.surgical_technique.$error ||
              $v.follicularPuncture.surgical_technique.$invalid,
          }"
          name="surgical_technique"
          id="surgical_technique"
          cols="30"
          rows="5"></textarea>
      </div>
      <!-- surgical_technique /-->
      <!-- ACTION BUTTONS -->
      <div class="row pt-5 justify-content-end">
        <div class="col-2" v-if="isEditActive">
          <!-- CANCEL -->
          <button
            class="btn btn-secondary me-1 w-100"
            @click="changeEditStatus(false)"
            v-text="'Cancelar'"
            type="button" />
          <!-- CANCEL /-->
        </div>
        <div class="col-2" v-if="isEditActive">
          <!-- GUARDAR -->
          <button
            class="btn btn-primary me-1 w-100"
            type="submit"
            v-text="'Guardar'" />
          <!-- GUARDAR /-->
        </div>
      </div>
      <!-- ACTION BUTTONS /-->
    </form>
  </div>
</template>

<script>
import Form from "@/helpers/form";
import { required, requiredIf } from "vuelidate/lib/validators";
import surgeryForms from "@/mixin/surgeryForms.mixin";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "FollicularPunctureForm",
  props: {
    idSurgeryEvent: {
      type: Number,
    },
  },
  components: {
    DatePicker,
  },
  mixins: [surgeryForms],
  mounted() {
    if (this.idSurgeryEvent) {
      this.fillInfo();
    }
  },
  data() {
    return {
      stimulated_bys: [],
      puncture_medics: [],
      biologists: [],
      nurses: [],
      anesthesiologists: [],
      follicularPuncture: new Form({
        stimulated_by_id: null,
        puncture_medic_id: null,
        puncture_pathway: 1,
        ovules_expected: null,
        ovules_obtained: null,
        biologist_id: null,
        nurse_id: null,
        time_entry: null,
        time_departure: null,
        anesthesia: null,
        anesthesia_other: null,
        anesthesiologist_id: null,
        complications: null,
        complications_description: null,
        surgical_technique: null,
        stimulation_method: null,
        anesthesia_required: false,
      }),
    };
  },
  validations: {
    follicularPuncture: {
      stimulated_by_id: {
        required,
      },
      puncture_medic_id: {
        required,
      },
      puncture_pathway: {
        required,
      },
      stimulation_method: {
        required,
      },
      ovules_expected: {
        required,
      },
      ovules_obtained: {
        required,
      },
      biologist_id: {
        required,
      },
      nurse_id: {
        required,
      },
      time_entry: {
        required,
      },
      time_departure: {
        required,
      },
      anesthesia: {
        required: requiredIf(function anesthesiaRequired() {
          return this.follicularPuncture.anesthesia_required;
        }),
      },
      anesthesia_other: {
        required: requiredIf(function anesthesiaOther() {
          return this.follicularPuncture.anesthesia === "2";
        }),
      },
      anesthesiologist_id: {
        required: requiredIf(function anesthesiaRequired() {
          return this.follicularPuncture.anesthesia_required;
        }),
      },
      complications: {
        required,
      },
      complications_description: {
        required: requiredIf(function anesthesiaOther() {
          return !!this.follicularPuncture.complications;
        }),
      },
      surgical_technique: {
        required,
      },
    },
  },
  computed: {
    classesContructorDatePickStart() {
      if (
        this.$v.follicularPuncture.time_entry.$error ||
        this.$v.follicularPuncture.time_entry.$invalid
      ) {
        return "form-control inputsGeneralClassThird error";
      }
      return "form-control inputsGeneralClassThird";
    },
    classesContructorDatePickEnd() {
      if (
        this.$v.follicularPuncture.time_departure.$error ||
        this.$v.follicularPuncture.time_departure.$invalid
      ) {
        return "form-control inputsGeneralClassThird error";
      }
      return "form-control inputsGeneralClassThird";
    },
  },
  methods: {
    fillInfo() {
      this.$api.patient
        .getFollicularPuncture({
          operation_room_id: this.idSurgeryEvent,
        })
        .then((res) => {
          this.follicularPuncture.update(res.data);
          this.fillSearchOptions(
            "anesthesiologist",
            res.data.anesthesiologist_name
          );
          this.fillSearchOptions("biologist", res.data.biologist_name);
          this.fillSearchOptions("nurse", res.data.nurse_name);
          this.fillSearchOptions(
            "puncture_medic",
            res.data.puncture_medic_name
          );
          this.fillSearchOptions("stimulated_by", res.data.stimulated_by_name);
        });
    },
    cancelFormEdit() {
      this.follicularPuncture.reset();
      this.fillInfo();
    },
    onSubmit() {
      if (!this.isFormDataValid()) return;
      const payload = this.follicularPuncture.data();
      payload.operation_room_id = this.idSurgeryEvent;
      this.$api.patient.saveFollicularPuncture(payload).then(() => {
        this.changeEditStatus(false);
      });
    },
  },
};
</script>
