<template>
  <div class="surgical m-4">
    <div class="row m-0 p-0">
      <div class="titles col-6">Quirúrgica</div>
      <div class="col-6 text-end" v-if="!isEditActive">
        <!-- ACTIVE EDIT -->
        <button
          class="btn btn-primary me-1 w-20"
          @click="changeEditStatus(true)"
          v-text="'Editar'"
          type="button" />
        <!-- ACTIVE EDIT /-->
      </div>
    </div>
    <form class="mt-4" @submit.stop.prevent="onSubmit">
      <div class="row mb-3">
        <!-- surgery_type -->
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-2 col-form-label m-auto" for="nec">
              Tipo de Cirugía
            </label>
            <div class="col-sm-7 col-lg-10">
              <select
                v-model="surgical.surgery_type"
                :disabled="!isEditActive"
                :class="{
                  error:
                    $v.surgical.surgery_type.$error ||
                    $v.surgical.surgery_type.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50">
                <option value="null" disabled selected>Tipo de Cirugía</option>
                <option :value="1">Efectiva</option>
                <option :value="2">Urgente</option>
              </select>
            </div>
          </div>
        </div>
        <!-- surgery_type /-->
      </div>
      <div class="row mb-3">
        <!-- surgery_projected -->
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-2 col-form-label m-auto" for="nec">
              Cirugía Proyectada
            </label>
            <div class="col-sm-7 col-lg-10">
              <select
                v-model="surgical.surgery_projected"
                :disabled="!isEditActive"
                :class="{
                  error:
                    $v.surgical.surgery_projected.$error ||
                    $v.surgical.surgery_projected.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50">
                <option value="null" disabled selected>-</option>
                <option :value="1">B. Testicular</option>
                <option :value="2">H. Diagnosticada</option>
                <option :value="3">H. Quirúrgica</option>
                <option :value="5">IAH</option>
                <option :value="6">Punción Quiste</option>
                <option :value="7">AMEU</option>
                <option :value="8">Rejuv. Ovárico</option>
                <option :value="9">Aspiración de Epidídimo</option>
                <option :value="10">Culdocentesis</option>
                <option :value="4">Etc</option>
              </select>
            </div>
          </div>
        </div>
        <!-- surgery_projected /-->
        <!-- time_start -->
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-2 col-form-label m-auto" for="name">
              Hora Inicio
            </label>
            <div class="col-sm-7 col-lg-10">
              <date-picker
                v-model="surgical.time_start"
                :disabled="!isEditActive"
                format="HH:mm"
                value-type="format"
                :input-class="classesContructorDatePickStart"
                type="time" />
            </div>
          </div>
        </div>
        <!-- time_start /-->
      </div>
      <div class="row mb-3">
        <!-- surgery_performed -->
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-2 col-form-label m-auto" for="name">
              Cirugía Realizada
            </label>
            <div class="col-sm-7 col-lg-10">
              <select
                v-model="surgical.surgery_performed"
                :disabled="!isEditActive"
                :class="{
                  error:
                    $v.surgical.surgery_performed.$error ||
                    $v.surgical.surgery_performed.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50">
                <option value="null" disabled selected>-</option>
                <option :value="1">B. Testicular</option>
                <option :value="2">H. Diagnosticada</option>
                <option :value="3">H. Quirúrgica</option>
                <option :value="5">IAH</option>
                <option :value="6">Punción Quiste</option>
                <option :value="7">AMEU</option>
                <option :value="8">Rejuv. Ovárico</option>
                <option :value="9">Aspiración de Epidídimo</option>
                <option :value="10">Culdocentesis</option>
                <option :value="4">Etc</option>
              </select>
            </div>
          </div>
        </div>
        <!-- surgery_performed /-->
        <!-- time_end -->
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label class="col-sm-5 col-lg-2 col-form-label m-auto" for="nec">
              Hora Fin
            </label>
            <div class="col-sm-7 col-lg-10">
              <date-picker
                v-model="surgical.time_end"
                :disabled="!isEditActive"
                format="HH:mm"
                value-type="format"
                :input-class="classesContructorDatePickEnd"
                type="time" />
            </div>
          </div>
        </div>
        <!-- time_end /-->
      </div>
      <!-- surgical_technique -->
      <div class="row mb-3">
        <label class="mb-1" for="surgical_technique">
          Técnica Quirúrgica
        </label>
        <textarea
          v-model="surgical.surgical_technique"
          :disabled="!isEditActive"
          class="form-control inputsGeneralClassThird"
          :class="{
            error:
              $v.surgical.surgical_technique.$error ||
              $v.surgical.surgical_technique.$invalid,
          }"
          name="surgical_technique"
          id="surgical_technique"
          cols="30"
          rows="5"></textarea>
      </div>
      <!-- surgical_technique /-->
      <!-- anesthesiologist -->
      <div class="row mb-3">
        <!-- complications -->
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label
              class="col-sm-5 col-lg-2 col-form-label m-auto"
              for="complications">
              Requiere Anestesiólogo
            </label>
            <div class="col-sm-7 col-lg-10">
              <select
                v-model="surgical.anesthesiologist_required"
                :disabled="!isEditActive"
                class="form-control inputsGeneralClassThird winput-50">
                <option :value="true">Si</option>
                <option :value="false">No</option>
              </select>
            </div>
          </div>
        </div>
        <!-- complications /-->
      </div>
      <div class="row mb-3" v-if="surgical.anesthesiologist_required">
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label
              class="col-sm-5 col-lg-2 col-form-label m-auto"
              for="anesthesiologist">
              Anestesiólogo
            </label>
            <div class="col-sm-7 col-lg-10">
              <v-select
                label="name"
                v-model="surgical.anesthesiologist_id"
                :disabled="!isEditActive"
                class="form-control inputsGeneralClassThird winput-50 p-0"
                :class="{
                  error:
                    $v.surgical.anesthesiologist_id.$error ||
                    $v.surgical.anesthesiologist_id.$invalid,
                }"
                :filterable="false"
                :options="anesthesiologists"
                :reduce="(option) => option.id"
                @search="
                  (search, loading) =>
                    onSearch(search, loading, 'anesthesiologist')
                ">
                <template slot="no-options"> escribe para buscar... </template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.name }} - {{ typeUserSearch(option.external) }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.name }} - {{ typeUserSearch(option.external) }}
                  </div>
                </template>
              </v-select>
            </div>
          </div>
        </div>
      </div>
      <!-- anesthesiologist /-->
      <!-- surgeon -->
      <div class="row mb-3">
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label
              class="col-sm-5 col-lg-2 col-form-label m-auto"
              for="surgeon">
              Cirujano
            </label>
            <div class="col-sm-7 col-lg-10">
              <v-select
                label="name"
                v-model="surgical.surgeon_id"
                :disabled="!isEditActive"
                class="form-control inputsGeneralClassThird winput-50 p-0"
                :class="{
                  error:
                    $v.surgical.surgeon_id.$error ||
                    $v.surgical.surgeon_id.$invalid,
                }"
                :filterable="false"
                :options="surgeons"
                :reduce="(option) => option.id"
                @search="
                  (search, loading) => onSearch(search, loading, 'surgeon')
                ">
                <template slot="no-options"> escribe para buscar... </template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.name }} - {{ typeUserSearch(option.external) }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.name }} - {{ typeUserSearch(option.external) }}
                  </div>
                </template>
              </v-select>
            </div>
          </div>
        </div>
      </div>
      <!-- surgeon /-->
      <!-- assistant -->
      <div class="row mb-3">
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label
              class="col-sm-5 col-lg-2 col-form-label m-auto"
              for="assistant">
              Ayudante
            </label>
            <div class="col-sm-7 col-lg-10">
              <v-select
                label="name"
                v-model="surgical.assistant_id"
                :disabled="!isEditActive"
                class="form-control inputsGeneralClassThird winput-50 p-0"
                :class="{
                  error:
                    $v.surgical.assistant_id.$error ||
                    $v.surgical.assistant_id.$invalid,
                }"
                :filterable="false"
                :options="assistants"
                :reduce="(option) => option.id"
                @search="
                  (search, loading) => onSearch(search, loading, 'assistant')
                ">
                <template slot="no-options"> escribe para buscar... </template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.name }} - {{ typeUserSearch(option.external) }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.name }} - {{ typeUserSearch(option.external) }}
                  </div>
                </template>
              </v-select>
            </div>
          </div>
        </div>
      </div>
      <!-- assistant /-->
      <!-- nurse_1 -->
      <div class="row mb-3">
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label
              class="col-sm-5 col-lg-2 col-form-label m-auto"
              for="nurse_1">
              Enfermera 1
            </label>
            <div class="col-sm-7 col-lg-10">
              <v-select
                label="name"
                v-model="surgical.nurse_1_id"
                :disabled="!isEditActive"
                class="form-control inputsGeneralClassThird winput-50 p-0"
                :class="{
                  error:
                    $v.surgical.nurse_1_id.$error ||
                    $v.surgical.nurse_1_id.$invalid,
                }"
                :filterable="false"
                :options="nurses"
                :reduce="(option) => option.id"
                @search="
                  (search, loading) => onSearch(search, loading, 'nurse')
                ">
                <template slot="no-options"> escribe para buscar... </template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.name }} - {{ typeUserSearch(option.external) }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.name }} - {{ typeUserSearch(option.external) }}
                  </div>
                </template>
              </v-select>
            </div>
          </div>
        </div>
      </div>
      <!-- nurse_1 /-->
      <!-- nurse_2 -->
      <div class="row mb-3">
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label
              class="col-sm-5 col-lg-2 col-form-label m-auto"
              for="nurse_2">
              Enfermera 2
            </label>
            <div class="col-sm-7 col-lg-10">
              <v-select
                label="name"
                v-model="surgical.nurse_2_id"
                :disabled="!isEditActive"
                class="form-control inputsGeneralClassThird winput-50 p-0"
                :class="{
                  error:
                    $v.surgical.nurse_2_id.$error ||
                    $v.surgical.nurse_2_id.$invalid,
                }"
                :filterable="false"
                :options="nurses"
                :reduce="(option) => option.id"
                @search="
                  (search, loading) => onSearch(search, loading, 'nurse')
                ">
                <template slot="no-options"> escribe para buscar... </template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.name }} - {{ typeUserSearch(option.external) }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.name }} - {{ typeUserSearch(option.external) }}
                  </div>
                </template>
              </v-select>
            </div>
          </div>
        </div>
      </div>
      <!-- nurse_2 /-->
      <div class="row mb-3">
        <!-- complications -->
        <div class="col-6">
          <div class="form-group mb-2 row">
            <label
              class="col-sm-5 col-lg-2 col-form-label m-auto"
              for="complications">
              Complicaciones
            </label>
            <div class="col-sm-7 col-lg-10">
              <select
                v-model="surgical.complications"
                :disabled="!isEditActive"
                :class="{
                  error:
                    $v.surgical.complications.$error ||
                    $v.surgical.complications.$invalid,
                }"
                class="form-control inputsGeneralClassThird winput-50">
                <option :value="true">Si</option>
                <option :value="false">No</option>
              </select>
            </div>
          </div>
        </div>
        <!-- complications /-->
      </div>
      <!-- complications_description -->
      <div class="row mb-3" v-if="surgical.complications">
        <label class="mb-1" for="complications_description">
          Descripción de Complicaciones
        </label>
        <textarea
          v-model="surgical.complications_description"
          :disabled="!isEditActive"
          :class="{
            error:
              $v.surgical.complications_description.$error ||
              $v.surgical.complications_description.$invalid,
          }"
          class="form-control inputsGeneralClassThird"
          name="surgical_technique"
          id="surgical_technique"
          cols="30"
          rows="5"></textarea>
      </div>
      <!-- complications_description /-->
      <div class="row pt-5 justify-content-end">
        <div class="col-2" v-if="isEditActive">
          <!-- CANCEL -->
          <button
            class="btn btn-secondary me-1 w-100"
            @click="changeEditStatus(false)"
            v-text="'Cancelar'"
            type="button" />
          <!-- CANCEL /-->
        </div>
        <div class="col-2" v-if="isEditActive">
          <!-- GUARDAR -->
          <button
            class="btn btn-primary me-1 w-100"
            type="submit"
            v-text="'Guardar'" />
          <!-- GUARDAR /-->
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Form from "@/helpers/form";
import { required, requiredIf } from "vuelidate/lib/validators";
import surgeryForms from "@/mixin/surgeryForms.mixin";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "SurgicalForm",
  props: {
    idSurgeryEvent: {
      type: Number,
    },
  },
  components: {
    DatePicker,
  },
  mixins: [surgeryForms],
  mounted() {
    if (this.idSurgeryEvent) {
      this.fillInfo();
    }
  },
  data() {
    return {
      anesthesiologists: [],
      surgeons: [],
      assistants: [],
      nurses: [],
      surgical: new Form({
        surgery_projected: null,
        surgery_performed: null,
        surgery_type: null,
        time_start: null,
        time_end: null,
        surgical_technique: null,
        anesthesiologist_id: null,
        surgeon_id: null,
        assistant_id: null,
        nurse_1_id: null,
        nurse_2_id: null,
        complications: null,
        complications_description: null,
        anesthesiologist_required: false,
      }),
    };
  },
  validations: {
    surgical: {
      surgery_projected: {
        required,
      },
      surgery_performed: {
        required,
      },
      surgery_type: {
        required,
      },
      time_start: {
        required,
      },
      time_end: {
        required,
      },
      surgical_technique: {
        required,
      },
      anesthesiologist_id: {
        required: requiredIf(function anesthesiaRequired() {
          return this.surgical.anesthesiologist_required;
        }),
      },
      surgeon_id: {
        required,
      },
      assistant_id: {
        required,
      },
      nurse_1_id: {
        required,
      },
      nurse_2_id: {
        required,
      },
      complications: {
        required,
      },
      complications_description: {
        required: requiredIf(function anesthesiaOther() {
          return !!this.surgical.complications;
        }),
      },
    },
  },
  computed: {
    classesContructorDatePickStart() {
      if (
        this.$v.surgical.time_start.$error ||
        this.$v.surgical.time_start.$invalid
      ) {
        return "form-control inputsGeneralClassThird error";
      }
      return "form-control inputsGeneralClassThird";
    },
    classesContructorDatePickEnd() {
      if (
        this.$v.surgical.time_end.$error ||
        this.$v.surgical.time_end.$invalid
      ) {
        return "form-control inputsGeneralClassThird error";
      }
      return "form-control inputsGeneralClassThird";
    },
  },
  methods: {
    fillInfo() {
      this.$api.patient
        .getSurgical({
          operation_room_id: this.idSurgeryEvent,
        })
        .then((res) => {
          this.surgical.update(res.data);
          this.fillSearchOptions(
            "anesthesiologist",
            res.data.anesthesiologist_name
          );
          this.fillSearchOptions("surgeon", res.data.surgeon_name);
          this.fillSearchOptions("assistant", res.data.assistant_name);
          this.fillSearchOptions("nurse", res.data.nurse_1_name);
          this.fillSearchOptions("nurse", res.data.nurse_2_name);
        });
    },
    cancelFormEdit() {
      this.surgical.reset();
      this.fillInfo();
    },
    onSubmit() {
      if (!this.isFormDataValid()) return;
      const payload = this.surgical.data();
      payload.operation_room_id = this.idSurgeryEvent;
      this.$api.patient.saveSurgical(payload).then(() => {
        this.changeEditStatus(false);
      });
    },
  },
};
</script>

<style></style>
