import presurgical from "@/components/patient/surgery/forms/presurgical.vue";
import surgical from "@/components/patient/surgery/forms/surgical.vue";
import preanesthetic from "@/components/patient/surgery/forms/preanesthetic.vue";
import follicularPuncture from "@/components/patient/surgery/forms/follicularPuncture.vue";
import embryoTransfer from "@/components/patient/surgery/forms/embryoTransfer.vue";
import InfoPatient from "@/components/patient/surgery/forms/infoPatient.vue";
import SurgeryTabs from "@/components/patient/surgery/SurgeryTabs.vue";
import Search from "@/components/shared/Search.vue";

const components = {
  presurgical,
  surgical,
  preanesthetic,
  follicularPuncture,
  embryoTransfer,
  SurgeryTabs,
  Search,
  InfoPatient,
};

export default components;
