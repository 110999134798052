<template>
  <div class="surgeryPatientInfo form-horizontal mt-4">
    <div class="row align-items-center pb-5">
      <div class="col-12 col-md-6 col-lg-2">
        <div class="d-flex">
          <div class="col-3 text-black" for="nec">NEC:</div>
          <div class="col-6">{{ patientInfo.nec }}</div>
        </div>
      </div>
      <div
        class="
          col-12 col-md-6 col-lg-4 col-xl-4
          text-start text-md-end text-lg-start
        ">
        <div class="form-group row">
          <div
            class="
              col-4 col-md-3 col-lg-4
              offset-0 offset-md-1 offset-lg-0
              col-lg-4
              text-black
            "
            for="nec">
            Nombre:
          </div>
          <div class="col-8 col-md-8 col-lg-8">
            {{ patientInfo.name }}
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl-5 offset-xl-1 mt-3 mt-lg-0">
        <b-input-group prepend="Servicios">
          <select
            v-model="surgerySelected"
            @change="selectSurgery"
            class="
              form-control
              inputsGeneralClassThird inputsGeneralClassThird__formGroup
              w-50
            ">
            <option value="null" disabled selected>Servicios Realizados</option>
            <option
              v-for="surgery in surgeries"
              :key="surgery.id"
              :value="surgery.id">
              {{ surgery.date }}
            </option>
          </select>
          <b-input-group-append>
            <button
              @click="askToCreateSurgery()"
              class="btn-primary btn-outline-primary-add">
              <span class="d-none d-md-inline">Nuevo Servicio</span>
              <font-awesome-icon
                class="svg-inline--fa fa-search fa-w-16 ms-0 ms-md-1"
                :icon="['fas', 'plus']" />
            </button>
          </b-input-group-append>
        </b-input-group>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "InfoPatients",
  props: {
    patientInfo: {
      type: Object,
    },
  },
  data() {
    return {
      surgerySelected: null,
      surgeries: null,
    };
  },
  mounted() {
    if (this.patientInfo) {
      this.loadSurgeryData(this.patientInfo.patient_id);
    }
  },
  methods: {
    ...mapActions(["updateConfirmData"]),
    askToCreateSurgery() {
      this.updateConfirmData({
        title: "Crear Evento",
        description: `Se creara un evento nuevo para el usuario ${this.patientInfo.name}.`,
        params: null,
        okText: "Proceder",
        cancelText: "Cancelar",
        confirmFunction: this.createSurgery,
      });
    },
    createSurgery() {
      this.$api.patient
        .createSurgery({
          patient_id: this.patientInfo.patient_id,
          clinic_id: this.getClinic,
        })
        .then(() => {
          this.loadSurgeryData(this.patientInfo.patient_id);
        });
    },
    loadSurgeryData(id) {
      this.$api.patient
        .getSurgeries({ patient_id: id, clinic_id: this.getClinic })
        .then((res) => {
          this.surgeries = res.data.operation_rooms;
          if (this.surgeries.length > 0) {
            this.surgerySelected = this.surgeries[0].id;
            this.selectSurgery();
          }
        });
    },
    selectSurgery() {
      this.$emit("activeSurgeryView", this.surgerySelected);
    },
  },
  computed: {
    ...mapGetters(["getClinic"]),
  },
};
</script>
