<template>
  <div class="surgeryHome">
    <div class="surgeryHome__search card p-5">
      <Search
        class="pb-4"
        @searchData="searchPatient"
        :flexDirection="'flex-start'"
        :autoSearch="false"
        :inputDate="false" />
    </div>
    <div
      class="surgeryHome__main card p-5"
      v-if="!isSearchEmpty || isSelectedSurgery || isSelectedPatient">
      <transition name="scale" mode="out-in">
        <template v-if="!isSelectedPatient">
          <transition name="scale" mode="out-in">
            <b-table
              v-if="!isSearchEmpty"
              :items="patients"
              :fields="fieldsTable"
              :table-class="'patientsHeadClass'"
              @row-clicked="selectPatient"
              striped
              borderless>
              <template v-slot:cell(action)="">
                <font-awesome-icon
                  class="pointer tableIcon"
                  :icon="['fa', 'file-waveform']" />
              </template>
            </b-table>
          </transition>
        </template>
      </transition>
      <transition name="scale" mode="out-in">
        <template v-if="isSelectedPatient">
          <InfoPatient
            @activeSurgeryView="selectSurgery"
            @deselectPatient="deselectPatient"
            :patientInfo="infoPatient" />
        </template>
      </transition>
      <transition name="scale" mode="out-in">
        <div v-if="isSelectedSurgery">
          <SurgeryTabs
            @changeActiveComponent="changeActiveComponent"
            :isEditActive="isEditActive" />
          <transition name="scale" mode="out-in">
            <component
              ref="componentBaseSurgery"
              :is="type"
              :idSurgeryEvent="surgerySelected"
              @activeEdit="changeEditStatus" />
          </transition>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import surgeryComponents from "@/constants/patient/surgeryComponents";

export default {
  name: "SurgeryHome",
  components: surgeryComponents,
  data() {
    return {
      isEditActive: false,
      surgerySelected: null,
      type: "presurgical",
      isSearchEmpty: true,
      isSelectedPatient: false,
      isSelectedSurgery: false,
      infoPatient: null,
      patients: [],
      fieldsTable: [
        { class: "text-center text-primary", key: "nec", label: "NEC" },
        { class: "text-center", key: "name", label: "Nombre" },
        { class: "text-center", key: "action", label: "Seleccionar" },
      ],
    };
  },
  computed: {
    ...mapGetters(["getClinic"]),
  },
  methods: {
    changeEditStatus(status) {
      this.isEditActive = status;
    },
    selectSurgery(id) {
      this.isSelectedSurgery = false;
      this.surgerySelected = id;
      setTimeout(() => {
        this.isSelectedSurgery = true;
      }, 300);
    },
    deselectPatient() {
      this.infoPatient = null;
      this.isSelectedPatient = false;
      this.isSelectedSurgery = false;
    },
    selectPatient(item) {
      this.isSelectedPatient = true;
      this.infoPatient = item;
    },
    searchPatient(payload) {
      this.changeActiveComponent("presurgical");
      this.deselectPatient();
      payload.clinic_id = this.getClinic;
      payload.infoType = "patients";
      this.$api.patient.getPatientList(payload).then((res) => {
        this.isSearchEmpty = res.data.patients.length === 0;
        this.patients = res.data.patients;
      });
    },
    changeActiveComponent(activeComponent) {
      this.type = activeComponent;
    },
  },
};
</script>
