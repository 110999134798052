<template>
  <div class="presurgical m-4">
    <div class="row m-0 p-0 pb-3">
      <div class="titles col-6">Prequirúrgica</div>
      <div class="col-6 text-end" v-if="!isEditActive">
        <!-- ACTIVE EDIT -->
        <button
          class="btn btn-primary me-1 w-20"
          @click="changeEditStatus(true)"
          v-text="'Editar'"
          type="button" />
        <!-- ACTIVE EDIT /-->
      </div>
    </div>
    <!-- FORM -->
    <div class="row align-items-center justify-content-between mb-4">
      <div class="row justify-content-center col-5 align-items-center">
        <div class="col-lg-12 col-xl-6">
          <label>Ayuno Mayor a 8 hrs:</label>
        </div>
        <div class="col-lg-12 col-xl-6">
          <b-form-select
            v-model="presurgical.fast"
            class="form-select text-center inputsGeneralClassThird w-100"
            :disabled="!isEditActive">
            <option :value="null" disabled>Indicar</option>
            <option :value="true">Si</option>
            <option :value="false">No</option>
          </b-form-select>
        </div>
      </div>
      <div class="row justify-content-center col-4 align-items-center">
        <div class="col-lg-12 col-xl-6">
          <label>Consentimientos:</label>
        </div>
        <div class="col-lg-12 col-xl-6">
          <b-form-select
            v-model="presurgical.consents"
            class="form-select text-center inputsGeneralClassThird w-100"
            :disabled="!isEditActive">
            <option :value="null" disabled>Indicar</option>
            <option :value="true">Si</option>
            <option :value="false">No</option>
          </b-form-select>
        </div>
      </div>
      <div class="row justify-content-end col-3 align-items-center">
        <div class="col-lg-12 col-xl-6">
          <label>Serologias: </label>
        </div>
        <div class="col-lg-12 col-xl-6">
          <b-form-select
            v-model="presurgical.serologies"
            :state="
              !$v.presurgical.serologies.$invalid &&
              !$v.presurgical.serologies.$error
            "
            class="form-select text-center inputsGeneralClassThird w-100"
            :disabled="!isEditActive">
            <option :value="null" disabled>Indicar</option>
            <option
              v-for="(label, index) in serologiesLabels"
              :key="`serologies-${label.label}-${index}`"
              :value="label.value"
              v-text="label.label"></option>
          </b-form-select>
        </div>
      </div>
    </div>
    <div class="mb-3">
      <label class="mb-1">Comentarios:</label>
      <b-form-textarea
        v-model="presurgical.comments"
        :state="
          !$v.presurgical.comments.$invalid && !$v.presurgical.comments.$error
        "
        class="form-control inputsGeneralClass w-100"
        :disabled="!isEditActive"></b-form-textarea>
    </div>
    <div class="row pt-5 justify-content-end">
      <div class="col-2" v-if="isEditActive">
        <!-- CANCEL -->
        <button
          class="btn btn-secondary me-1 w-100"
          @click="changeEditStatus(false)"
          v-text="'Cancelar'"
          type="button" />
        <!-- CANCEL /-->
      </div>
      <div class="col-2" v-if="isEditActive">
        <!-- GUARDAR -->
        <button
          class="btn btn-primary me-1 w-100"
          @click="actionSave"
          type="button"
          v-text="'Guardar'" />
        <!-- GUARDAR /-->
      </div>
    </div>
    <!-- FORM END -->
  </div>
</template>

<script>
import Form from "@/helpers/form";
import { serologiesLabels } from "@/constants/patient";
import { required } from "vuelidate/lib/validators";
import surgeryForms from "@/mixin/surgeryForms.mixin";

export default {
  name: "Presurgical",
  props: {
    idSurgeryEvent: {
      type: Number,
    },
  },
  mixins: [surgeryForms],
  mounted() {
    if (this.idSurgeryEvent) {
      this.fillInfo();
    }
  },
  data() {
    return {
      presurgical: new Form({
        fast: null,
        consents: null,
        serologies: null,
        comments: "",
      }),
      serologiesLabels,
    };
  },
  validations: {
    presurgical: {
      fast: {
        required,
      },
      consents: {
        required,
      },
      serologies: {
        required,
      },
      comments: {
        required,
      },
    },
  },
  methods: {
    cancelFormEdit() {
      this.presurgical.reset();
      this.fillInfo();
    },
    fillInfo() {
      this.$api.patient
        .getPresurgical({
          operation_room_id: this.idSurgeryEvent,
        })
        .then((res) => {
          this.presurgical.update(res.data);
        });
    },
    actionSave() {
      if (!this.isFormDataValid()) return;
      const payload = this.presurgical.data();
      payload.operation_room_id = this.idSurgeryEvent;
      this.$api.patient.savePresurgical(payload).then(() => {
        this.changeEditStatus(false);
      });
    },
  },
};
</script>
