var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"nav nav-tabs"},[_c('li',{staticClass:"nav-item"},[_c('p',{staticClass:"nav-link",class:{
        active: _vm.type === 'presurgical',
        pointer: !_vm.isEditActive,
        disabled: _vm.isEditActive,
      },attrs:{"disabled":!_vm.isEditActive},on:{"click":function($event){return _vm.changeActiveTab('presurgical')}}},[_vm._v(" Prequirúrgica ")])]),_c('li',{staticClass:"nav-item"},[_c('p',{staticClass:"nav-link",class:{
        active: _vm.type === 'surgical',
        pointer: !_vm.isEditActive,
        disabled: _vm.isEditActive,
      },attrs:{"disabled":!_vm.isEditActive},on:{"click":function($event){return _vm.changeActiveTab('surgical')}}},[_vm._v(" Quirúrgica ")])]),(_vm.getUserType === 'superadmin')?_c('li',{staticClass:"nav-item"},[_c('p',{staticClass:"nav-link",class:{
        active: _vm.type === 'preanesthetic',
        pointer: !_vm.isEditActive,
        disabled: _vm.isEditActive,
      },on:{"click":function($event){return _vm.changeActiveTab('preanesthetic')}}},[_vm._v(" Preanestésica ")])]):_vm._e(),_c('li',{staticClass:"nav-item"},[_c('p',{staticClass:"nav-link",class:{
        active: _vm.type === 'follicularPuncture',
        pointer: !_vm.isEditActive,
        disabled: _vm.isEditActive,
      },attrs:{"disabled":!_vm.isEditActive},on:{"click":function($event){return _vm.changeActiveTab('follicularPuncture')}}},[_vm._v(" Punción Folicular ")])]),_c('li',{staticClass:"nav-item"},[_c('p',{staticClass:"nav-link",class:{
        active: _vm.type === 'embryoTransfer',
        pointer: !_vm.isEditActive,
        disabled: _vm.isEditActive,
      },attrs:{"disabled":!_vm.isEditActive},on:{"click":function($event){return _vm.changeActiveTab('embryoTransfer')}}},[_vm._v(" Transferencia Embrionaria ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }