import Form from "@/helpers/form";

const preanesthetic = new Form({
  alt: null,
  anesthetic_technique: null,
  asa: null,
  ast: null,
  bd: null,
  bi: null,
  bun: null,
  cardiovascular: null,
  cholesterol: null,
  ci: null,
  comments: null,
  creatinine: null,
  currently_pregnant: null,
  digestive: null,
  editable: true,
  family_history: null,
  family_history_other: null,
  gestation_weeks: null,
  glucose: null,
  goldman: null,
  hb: null,
  hto: null,
  inr: null,
  k: null,
  leukocytes: null,
  malampati: null,
  neurological: null,
  nyha: null,
  // operation room data
  operation_room_id: 0,
  operation_room_preanesthetic_id: 0,
  // operation room data end
  patil_aldretti: null,
  personal_history: null,
  personal_history_other: null,
  personal_history_pathological: null,
  personal_history_pathological_other: null,
  platelets: null,
  projected_surgery: null,
  renal: null,
  respiratory: null,
  skeletal_muscle: null,
  sleep_disorder: null,
  thromboembolic_risk: null,
  tp: null,
  tpt: null,
  triglycerides: null,
  urea: null,
});

const heredorelativeLabels = [
  { label: "Diabetes", value: "diabetes" },
  { label: "Hipertensión", value: "hypertension" },
  { label: "Infarto al miocardio", value: "myocardial_infarction" },
  { label: "Cancer", value: "cancer" },
  { label: "Artritis Reumatoide", value: "rheumatoid_arthritis" },
];

const notPathologicalsLabels = [
  { label: "Tabaquismo", value: "smoking" },
  { label: "Alcoholismo", value: "alcoholism" },
  { label: "Toxicomanias", value: "drug_addiction" },
  { label: "Exposición del humo de leña", value: "wood_smoke_exposure" },
  { label: "Sedentarismo", value: "sedentary_lifestyle" },
  { label: "Dieta", value: "diet" },
  { label: "Ejercicio", value: "exercise" },
  { label: "Protesis Dentales", value: "dental_prostheses" },
  { label: "Inmunizaciones", value: "immunizations" },
];

const pathologicalsLabels = [
  { label: "Alergico", value: "allergy" },
  { label: "Asma", value: "asthma" },
  { label: "Anestesico", value: "anesthetics" },
  { label: "Cancer", value: "cancer" },
  { label: "Cirugias", value: "surgeries" },
  { label: "Diabetes", value: "diabetes" },
  { label: "Fracturas", value: "fractures" },
  { label: "Hipoacusia", value: "hearing_loss" },
  { label: "Hematológicos", value: "hematological" },
];

const cardiovascularLabels = [
  { label: "Disnea", value: "dyspnea" },
  { label: "Soplos", value: "sopios" },
  { label: "Palpitaciones", value: "palpitations" },
  { label: "Dificultad Respiratoria", value: "shortness_breath" },
];

const respiratoryLabels = [
  { label: "Tos", value: "cough" },
  { label: "Expectoración", value: "expectoration" },
  { label: "Subilancias", value: "wheezing" },
];

const renalLabels = [
  { label: "Disuria", value: "dysuria" },
  { label: "Poliuria", value: "polyuria" },
  { label: "Anuria", value: "anuria" },
];

const digestiveLabels = [
  { label: "Dolor", value: "pain" },
  { label: "Distersión", value: "bloating" },
  { label: "Gastritis", value: "gastritis" },
  { label: "Colitis", value: "colitis" },
  { label: "extreñiento", value: "constipation" },
];

const skeletalmuscleLabels = [
  { label: "Artralgías", value: "arthralgias" },
  { label: "Mialgas", value: "myalgias" },
  { label: "Deformidad", value: "deformity" },
  { label: "Incapacidad", value: "disability" },
  { label: "Fracturas", value: "fractures" },
];

const sleepDisorderLabels = [
  { label: "Insomnio", value: "insomnia" },
  { label: "Amnea", value: "apnea" },
  { label: "Roncopatia", value: "snoring" },
];

const neurologicalsLabels = [
  { label: "Vértigo", value: "vertigo" },
  { label: "Cefalea", value: "headache" },
  { label: "Ansiedad", value: "anxiety" },
  { label: "Depresión", value: "depression" },
];

const ASALabels = [
  { label: "Efectiva", value: "effective" },
  { label: "Urgencia", value: "urgency" },
  { label: "I", value: "i" },
  { label: "II", value: "ii" },
  { label: "III", value: "iii" },
  { label: "IV", value: "iv" },
];

const anestheticLabels = [
  { label: "Local", value: "local" },
  { label: "Local sedación", value: "local_sedation" },
  { label: "Bloqueo Regional", value: "regional_block" },
  { label: "General", value: "general" },
];

const labels = {
  heredorelativeLabels,
  notPathologicalsLabels,
  pathologicalsLabels,
  cardiovascularLabels,
  respiratoryLabels,
  renalLabels,
  digestiveLabels,
  skeletalmuscleLabels,
  sleepDisorderLabels,
  neurologicalsLabels,
  ASALabels,
  anestheticLabels,
  preanesthetic,
};

export default labels;
