var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"surgical m-4"},[_c('div',{staticClass:"row m-0 p-0"},[_c('div',{staticClass:"titles col-6"},[_vm._v("Preanestésica")]),(!_vm.isEditActive)?_c('div',{staticClass:"col-6 text-end"},[_c('button',{staticClass:"btn btn-primary me-1 w-20",attrs:{"type":"button"},domProps:{"textContent":_vm._s('Editar')},on:{"click":function($event){return _vm.changeEditStatus(true)}}})]):_vm._e()]),_c('form',{staticClass:"mt-4",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.actionSave.apply(null, arguments)}}},[_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"nec"}},[_vm._v(" Cirugía Proyectada ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.projected_surgery),expression:"preanesthetic.projected_surgery"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.projected_surgery.$error ||
                  _vm.$v.preanesthetic.projected_surgery.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.preanesthetic, "projected_surgery", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"null","disabled":"","selected":""}},[_vm._v("Tipo de Cirugía")]),_c('option',{domProps:{"value":'effective'}},[_vm._v("Efectiva")]),_c('option',{domProps:{"value":'urgent'}},[_vm._v("Urgente")])])])])])]),_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"name"}},[_vm._v(" Antecedentes Heredofamiliares ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.family_history),expression:"preanesthetic.family_history"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.family_history.$error ||
                  _vm.$v.preanesthetic.family_history.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.preanesthetic, "family_history", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"null","disabled":"","selected":""}},[_vm._v(" Tipo de Antecedente ")]),_vm._l((_vm.importedConstants.heredorelativeLabels),function(label,index){return _c('option',{key:("diabetes-" + (label.label) + "-" + index),domProps:{"value":label.value,"textContent":_vm._s(label.label)}})})],2)])])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"nec"}},[_vm._v(" Otros ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.family_history_other),expression:"preanesthetic.family_history_other"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.family_history_other.$error ||
                  _vm.$v.preanesthetic.family_history_other.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},domProps:{"value":(_vm.preanesthetic.family_history_other)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.preanesthetic, "family_history_other", $event.target.value)}}})])])])]),_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"name"}},[_vm._v(" Antecedentes personales no patológicos ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.personal_history),expression:"preanesthetic.personal_history"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.personal_history.$error ||
                  _vm.$v.preanesthetic.personal_history.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.preanesthetic, "personal_history", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"null","disabled":"","selected":""}},[_vm._v(" Tipo de Antecedente ")]),_vm._l((_vm.importedConstants.notPathologicalsLabels),function(label,index){return _c('option',{key:("diabetes-" + (label.label) + "-" + index),domProps:{"value":label.value,"textContent":_vm._s(label.label)}})})],2)])])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"nec"}},[_vm._v(" Otros ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.personal_history_other),expression:"preanesthetic.personal_history_other"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.personal_history_other.$error ||
                  _vm.$v.preanesthetic.personal_history_other.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},domProps:{"value":(_vm.preanesthetic.personal_history_other)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.preanesthetic, "personal_history_other", $event.target.value)}}})])])])]),_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"name"}},[_vm._v(" Antecedentes personales patológicos ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.personal_history_pathological),expression:"preanesthetic.personal_history_pathological"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.personal_history_pathological.$error ||
                  _vm.$v.preanesthetic.personal_history_pathological.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.preanesthetic, "personal_history_pathological", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"null","disabled":"","selected":""}},[_vm._v(" Tipo de Antecedente ")]),_vm._l((_vm.importedConstants.pathologicalsLabels),function(label,index){return _c('option',{key:("diabetes-" + (label.label) + "-" + index),domProps:{"value":label.value,"textContent":_vm._s(label.label)}})})],2)])])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"nec"}},[_vm._v(" Otros ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.personal_history_pathological_other),expression:"preanesthetic.personal_history_pathological_other"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.personal_history_pathological_other
                    .$error ||
                  _vm.$v.preanesthetic.personal_history_pathological_other
                    .$invalid,
              },attrs:{"disabled":!_vm.isEditActive},domProps:{"value":(_vm.preanesthetic.personal_history_pathological_other)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.preanesthetic, "personal_history_pathological_other", $event.target.value)}}})])])])]),_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"name"}},[_vm._v(" Embarazada Actualmente ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.currently_pregnant),expression:"preanesthetic.currently_pregnant"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.currently_pregnant.$error ||
                  _vm.$v.preanesthetic.currently_pregnant.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.preanesthetic, "currently_pregnant", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"null","disabled":"","selected":""}},[_vm._v(" Seleccione una opción ")]),_c('option',{domProps:{"value":true}},[_vm._v("Si")]),_c('option',{domProps:{"value":false}},[_vm._v("No")])])])])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"nec"}},[_vm._v(" Semanas de Gestación ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.gestation_weeks),expression:"preanesthetic.gestation_weeks"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.gestation_weeks.$error ||
                  _vm.$v.preanesthetic.gestation_weeks.$invalid,
              },attrs:{"type":"number","disabled":!_vm.isEditActive},domProps:{"value":(_vm.preanesthetic.gestation_weeks)},on:{"keypress":function($event){return _vm.isNumber($event)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.preanesthetic, "gestation_weeks", $event.target.value)}}})])])])]),_c('div',{staticClass:"row mb-5"},[_c('label',{staticClass:"mb-1",attrs:{"for":"surgical_technique"}},[_vm._v(" Comentarios ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.comments),expression:"preanesthetic.comments"}],staticClass:"form-control inputsGeneralClassThird",class:{
          error:
            _vm.$v.preanesthetic.comments.$error ||
            _vm.$v.preanesthetic.comments.$invalid,
        },attrs:{"disabled":!_vm.isEditActive,"name":"surgical_technique","id":"surgical_technique","cols":"30","rows":"5"},domProps:{"value":(_vm.preanesthetic.comments)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.preanesthetic, "comments", $event.target.value)}}})]),_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"name"}},[_vm._v(" Cardiovascular ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.cardiovascular),expression:"preanesthetic.cardiovascular"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.cardiovascular.$error ||
                  _vm.$v.preanesthetic.cardiovascular.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.preanesthetic, "cardiovascular", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"null","disabled":"","selected":""}},[_vm._v(" Seleccione una opción ")]),_vm._l((_vm.importedConstants.cardiovascularLabels),function(label,index){return _c('option',{key:("diabetes-" + (label.label) + "-" + index),domProps:{"value":label.value,"textContent":_vm._s(label.label)}})})],2)])])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"name"}},[_vm._v(" Respiratorio ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.respiratory),expression:"preanesthetic.respiratory"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.respiratory.$error ||
                  _vm.$v.preanesthetic.respiratory.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.preanesthetic, "respiratory", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"null","disabled":"","selected":""}},[_vm._v(" Seleccione una opción ")]),_vm._l((_vm.importedConstants.respiratoryLabels),function(label,index){return _c('option',{key:("diabetes-" + (label.label) + "-" + index),domProps:{"value":label.value,"textContent":_vm._s(label.label)}})})],2)])])])]),_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"name"}},[_vm._v(" Renal ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.renal),expression:"preanesthetic.renal"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.renal.$error ||
                  _vm.$v.preanesthetic.renal.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.preanesthetic, "renal", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"null","disabled":"","selected":""}},[_vm._v(" Seleccione una opción ")]),_vm._l((_vm.importedConstants.renalLabels),function(label,index){return _c('option',{key:("diabetes-" + (label.label) + "-" + index),domProps:{"value":label.value,"textContent":_vm._s(label.label)}})})],2)])])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"name"}},[_vm._v(" Digestivo ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.digestive),expression:"preanesthetic.digestive"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.digestive.$error ||
                  _vm.$v.preanesthetic.digestive.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.preanesthetic, "digestive", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"null","disabled":"","selected":""}},[_vm._v(" Seleccione una opción ")]),_vm._l((_vm.importedConstants.digestiveLabels),function(label,index){return _c('option',{key:("diabetes-" + (label.label) + "-" + index),domProps:{"value":label.value,"textContent":_vm._s(label.label)}})})],2)])])])]),_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"name"}},[_vm._v(" Músculo esquelético ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.skeletal_muscle),expression:"preanesthetic.skeletal_muscle"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.skeletal_muscle.$error ||
                  _vm.$v.preanesthetic.skeletal_muscle.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.preanesthetic, "skeletal_muscle", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"null","disabled":"","selected":""}},[_vm._v(" Seleccione una opción ")]),_vm._l((_vm.importedConstants.skeletalmuscleLabels),function(label,index){return _c('option',{key:("diabetes-" + (label.label) + "-" + index),domProps:{"value":label.value,"textContent":_vm._s(label.label)}})})],2)])])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"name"}},[_vm._v(" Transtorno de Sueño ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.sleep_disorder),expression:"preanesthetic.sleep_disorder"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.sleep_disorder.$error ||
                  _vm.$v.preanesthetic.sleep_disorder.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.preanesthetic, "sleep_disorder", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"null","disabled":"","selected":""}},[_vm._v(" Seleccione una opción ")]),_vm._l((_vm.importedConstants.sleepDisorderLabels),function(label,index){return _c('option',{key:("diabetes-" + (label.label) + "-" + index),domProps:{"value":label.value,"textContent":_vm._s(label.label)}})})],2)])])])]),_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"name"}},[_vm._v(" Neurológico ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.neurological),expression:"preanesthetic.neurological"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.neurological.$error ||
                  _vm.$v.preanesthetic.neurological.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.preanesthetic, "neurological", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"null","disabled":"","selected":""}},[_vm._v(" Seleccione una opción ")]),_vm._l((_vm.importedConstants.neurologicalsLabels),function(label,index){return _c('option',{key:("diabetes-" + (label.label) + "-" + index),domProps:{"value":label.value,"textContent":_vm._s(label.label)}})})],2)])])])]),_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"name"}},[_vm._v(" Mallapanti ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.malampati),expression:"preanesthetic.malampati"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.malampati.$error ||
                  _vm.$v.preanesthetic.malampati.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},domProps:{"value":(_vm.preanesthetic.malampati)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.preanesthetic, "malampati", $event.target.value)}}})])])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"nec"}},[_vm._v(" Patil Andreti ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.patil_aldretti),expression:"preanesthetic.patil_aldretti"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.patil_aldretti.$error ||
                  _vm.$v.preanesthetic.patil_aldretti.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},domProps:{"value":(_vm.preanesthetic.patil_aldretti)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.preanesthetic, "patil_aldretti", $event.target.value)}}})])])])]),_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"name"}},[_vm._v(" ASA ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.asa),expression:"preanesthetic.asa"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.asa.$error ||
                  _vm.$v.preanesthetic.asa.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.preanesthetic, "asa", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"null","disabled":"","selected":""}},[_vm._v(" Seleccione una opción ")]),_vm._l((_vm.importedConstants.ASALabels),function(label,index){return _c('option',{key:("diabetes-" + (label.label) + "-" + index),domProps:{"value":label.value,"textContent":_vm._s(label.label)}})})],2)])])])]),_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"name"}},[_vm._v(" NYHA ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.nyha),expression:"preanesthetic.nyha"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.nyha.$error ||
                  _vm.$v.preanesthetic.nyha.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},domProps:{"value":(_vm.preanesthetic.nyha)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.preanesthetic, "nyha", $event.target.value)}}})])])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"nec"}},[_vm._v(" GOLDMAN ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.goldman),expression:"preanesthetic.goldman"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.goldman.$error ||
                  _vm.$v.preanesthetic.goldman.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},domProps:{"value":(_vm.preanesthetic.goldman)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.preanesthetic, "goldman", $event.target.value)}}})])])])]),_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-6 col-lg-4 col-form-label m-auto",attrs:{"for":"name"}},[_vm._v(" Riesgo tromboembólico ")]),_c('div',{staticClass:"col-sm-6 col-lg-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.thromboembolic_risk),expression:"preanesthetic.thromboembolic_risk"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.thromboembolic_risk.$error ||
                  _vm.$v.preanesthetic.thromboembolic_risk.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},domProps:{"value":(_vm.preanesthetic.thromboembolic_risk)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.preanesthetic, "thromboembolic_risk", $event.target.value)}}})])])])]),_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"name"}},[_vm._v(" HB ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.hb),expression:"preanesthetic.hb"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.hb.$error || _vm.$v.preanesthetic.hb.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},domProps:{"value":(_vm.preanesthetic.hb)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.preanesthetic, "hb", $event.target.value)}}})])])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"nec"}},[_vm._v(" Glucosa ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.glucose),expression:"preanesthetic.glucose"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.glucose.$error ||
                  _vm.$v.preanesthetic.glucose.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},domProps:{"value":(_vm.preanesthetic.glucose)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.preanesthetic, "glucose", $event.target.value)}}})])])])]),_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"name"}},[_vm._v(" Hto ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.hto),expression:"preanesthetic.hto"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.hto.$error ||
                  _vm.$v.preanesthetic.hto.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},domProps:{"value":(_vm.preanesthetic.hto)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.preanesthetic, "hto", $event.target.value)}}})])])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"nec"}},[_vm._v(" Urea ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.urea),expression:"preanesthetic.urea"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.urea.$error ||
                  _vm.$v.preanesthetic.urea.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},domProps:{"value":(_vm.preanesthetic.urea)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.preanesthetic, "urea", $event.target.value)}}})])])])]),_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"name"}},[_vm._v(" Plaquetas ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.platelets),expression:"preanesthetic.platelets"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.platelets.$error ||
                  _vm.$v.preanesthetic.platelets.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},domProps:{"value":(_vm.preanesthetic.platelets)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.preanesthetic, "platelets", $event.target.value)}}})])])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"nec"}},[_vm._v(" Creatina ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.creatinine),expression:"preanesthetic.creatinine"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.creatinine.$error ||
                  _vm.$v.preanesthetic.creatinine.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},domProps:{"value":(_vm.preanesthetic.creatinine)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.preanesthetic, "creatinine", $event.target.value)}}})])])])]),_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"name"}},[_vm._v(" Leocusitos ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.leukocytes),expression:"preanesthetic.leukocytes"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.leukocytes.$error ||
                  _vm.$v.preanesthetic.leukocytes.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},domProps:{"value":(_vm.preanesthetic.leukocytes)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.preanesthetic, "leukocytes", $event.target.value)}}})])])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"nec"}},[_vm._v(" BUN ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.bun),expression:"preanesthetic.bun"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.bun.$error ||
                  _vm.$v.preanesthetic.bun.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},domProps:{"value":(_vm.preanesthetic.bun)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.preanesthetic, "bun", $event.target.value)}}})])])])]),_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"name"}},[_vm._v(" TP ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.tp),expression:"preanesthetic.tp"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.tp.$error || _vm.$v.preanesthetic.tp.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},domProps:{"value":(_vm.preanesthetic.tp)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.preanesthetic, "tp", $event.target.value)}}})])])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"nec"}},[_vm._v(" AST ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.ast),expression:"preanesthetic.ast"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.ast.$error ||
                  _vm.$v.preanesthetic.ast.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},domProps:{"value":(_vm.preanesthetic.ast)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.preanesthetic, "ast", $event.target.value)}}})])])])]),_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"name"}},[_vm._v(" TPT ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.tpt),expression:"preanesthetic.tpt"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.tpt.$error ||
                  _vm.$v.preanesthetic.tpt.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},domProps:{"value":(_vm.preanesthetic.tpt)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.preanesthetic, "tpt", $event.target.value)}}})])])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"nec"}},[_vm._v(" ALT ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.alt),expression:"preanesthetic.alt"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.alt.$error ||
                  _vm.$v.preanesthetic.alt.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},domProps:{"value":(_vm.preanesthetic.alt)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.preanesthetic, "alt", $event.target.value)}}})])])])]),_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"name"}},[_vm._v(" INR ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.inr),expression:"preanesthetic.inr"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.inr.$error ||
                  _vm.$v.preanesthetic.inr.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},domProps:{"value":(_vm.preanesthetic.inr)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.preanesthetic, "inr", $event.target.value)}}})])])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"nec"}},[_vm._v(" BD ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.bd),expression:"preanesthetic.bd"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.bd.$error || _vm.$v.preanesthetic.bd.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},domProps:{"value":(_vm.preanesthetic.bd)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.preanesthetic, "bd", $event.target.value)}}})])])])]),_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-6"}),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"nec"}},[_vm._v(" BI ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.bi),expression:"preanesthetic.bi"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.bi.$error || _vm.$v.preanesthetic.bi.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},domProps:{"value":(_vm.preanesthetic.bi)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.preanesthetic, "bi", $event.target.value)}}})])])])]),_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"name"}},[_vm._v(" K ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.k),expression:"preanesthetic.k"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.k.$error || _vm.$v.preanesthetic.k.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},domProps:{"value":(_vm.preanesthetic.k)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.preanesthetic, "k", $event.target.value)}}})])])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"nec"}},[_vm._v(" Técnica Anestesica ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.anesthetic_technique),expression:"preanesthetic.anesthetic_technique"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.anesthetic_technique.$error ||
                  _vm.$v.preanesthetic.anesthetic_technique.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.preanesthetic, "anesthetic_technique", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"null","disabled":"","selected":""}},[_vm._v(" Seleccione una opción ")]),_vm._l((_vm.importedConstants.anestheticLabels),function(label,index){return _c('option',{key:("diabetes-" + (label.label) + "-" + index),domProps:{"value":label.value,"textContent":_vm._s(label.label)}})})],2)])])])]),_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"name"}},[_vm._v(" CI ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.ci),expression:"preanesthetic.ci"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.ci.$error || _vm.$v.preanesthetic.ci.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},domProps:{"value":(_vm.preanesthetic.ci)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.preanesthetic, "ci", $event.target.value)}}})])])])]),_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"name"}},[_vm._v(" Colesterol ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.cholesterol),expression:"preanesthetic.cholesterol"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.cholesterol.$error ||
                  _vm.$v.preanesthetic.cholesterol.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},domProps:{"value":(_vm.preanesthetic.cholesterol)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.preanesthetic, "cholesterol", $event.target.value)}}})])])])]),_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group mb-2 row"},[_c('label',{staticClass:"col-sm-5 col-lg-4 col-form-label m-auto",attrs:{"for":"name"}},[_vm._v(" Triglicéridos ")]),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.preanesthetic.triglycerides),expression:"preanesthetic.triglycerides"}],staticClass:"form-control inputsGeneralClassThird winput-50",class:{
                error:
                  _vm.$v.preanesthetic.triglycerides.$error ||
                  _vm.$v.preanesthetic.triglycerides.$invalid,
              },attrs:{"disabled":!_vm.isEditActive},domProps:{"value":(_vm.preanesthetic.triglycerides)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.preanesthetic, "triglycerides", $event.target.value)}}})])])])]),_c('div',{staticClass:"row pt-5 justify-content-end"},[(_vm.isEditActive)?_c('div',{staticClass:"col-2"},[_c('button',{staticClass:"btn btn-secondary me-1 w-100",attrs:{"type":"button"},domProps:{"textContent":_vm._s('Cancelar')},on:{"click":function($event){return _vm.changeEditStatus(false)}}})]):_vm._e(),(_vm.isEditActive)?_c('div',{staticClass:"col-2"},[_c('button',{staticClass:"btn btn-primary me-1 w-100",attrs:{"type":"submit"},domProps:{"textContent":_vm._s('Guardar')}})]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }